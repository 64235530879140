import React, { useState, useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import PhoneCallback from "@material-ui/icons/PhoneCallback";
import appLogo from "../../assets/img/logo-white.svg";
import { useHistory } from "react-router-dom";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import queryString from 'query-string';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import {
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { getSession, removeSession } from "../../ultils/appSession";

import fetchClient from "../../ultils/request";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoImage: {
    maxHeight: 30,
  },
  headerText: {
    fontSize: 16,
  },
}));

export default function PrimarySearchAppBar() {
  let history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => setOpenDialog(true)}>Đổi mật khẩu</MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          logOut();
        }}
      >
        Đăng xuất
      </MenuItem>
    </Menu>
  );

  const gotoHistoryPage = () => {
    history.push("/user/lich-su-goi");
    handleMobileMenuClose();
    handleMobileMenuClose();
  };

  const gotoCallPage = () => {
    history.push("/user/goi");
    handleMobileMenuClose();
    handleMobileMenuClose();
  };

  const logOut = () => {
    removeSession();
    history.push("/auth/login");
  };

  const closeDialog = () => {
    handleMenuClose();
    setOpenDialog(false);
  }
  const openDialogUpdate = () => {
    setOpenDialog(true);
    handleMenuClose();
  }

  const handerApiError = (error) => {
    setErrorMessage(error);
  };

  const inCallPage = () => {
    return window.location.pathname.includes("/user/goi");
  };

  const currentSession = getSession();

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {inCallPage() ? (
        <MenuItem onClick={gotoHistoryPage}>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={0} color="secondary">
              <PhoneCallback />
            </Badge>
          </IconButton>
          <p>Lịch sử cuộc gọi</p>
        </MenuItem>
      ) : (
        <MenuItem onClick={gotoCallPage}>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={0} color="secondary">
              <PhoneForwardedIcon />
            </Badge>
          </IconButton>
          <p>Gọi khách hàng</p>
        </MenuItem>
      )}
      <MenuItem onClick={openDialogUpdate}>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <VpnKeyIcon />
          </Badge>
        </IconButton>
        <p>Đổi mật khẩu1</p>
      </MenuItem>
      <MenuItem onClick={logOut}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <PowerSettingsNew />
        </IconButton>
        <p>Đăng xuất</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <img src={appLogo} alt="app logo" className={classes.logoImage} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {inCallPage() ? (
              <IconButton
                size="small"
                color="inherit"
                onClick={gotoHistoryPage}
                className={classes.headerText}
              >
                <PhoneCallback /> &nbsp; Lịch sử cuộc gọi &nbsp; &nbsp;
              </IconButton>
            ) : (
              <IconButton
                size="small"
                color="inherit"
                onClick={gotoCallPage}
                className={classes.headerText}
              >
                <PhoneForwardedIcon /> &nbsp; Gọi khách hàng &nbsp; &nbsp;
              </IconButton>
            )}

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              className={classes.headerText}
            >
              <AccountCircle /> &nbsp; {currentSession.userName}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <UpdatePassword
        openDialog={openDialog}
        handleCloseDialog={closeDialog}
        handerApiError={handerApiError}
        history={history}
      >
      </UpdatePassword>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={10000}
          onClose={() => {
            setErrorMessage("");
          }}
        >
          <MuiAlert severity="warning">
            <b>{errorMessage}</b>
          </MuiAlert>
        </Snackbar>
      )}
    </div>
  );
}

const UpdatePassword = (props) => {
  const { openDialog, handleCloseDialog, handerApiError, history } = props
  const [errorText, setErrorText] = useState({});
  const [errorField, setErrorField] = useState({});
  const [password, setPassword] = useState({
    passwordNew: '',
    passwordOld: '',
  });

  const closeDialog = () => {
    setErrorText({});
    setErrorField({});
    setPassword({
      passwordNew: '',
      passwordOld: '',
    });
    handleCloseDialog();
  }

  const handleSubmit = async () => {
    try {
      var query = queryString.stringify({ passwordNew: password.passwordNew, passwordOld: password.passwordOld }, { arrayFormat: 'comma' });
      var result = await fetchClient.post(`/identity/reset-password-user/?${query}`);
      return result;
    } catch (error) {
      handerApiError("Thông tin mật khẩu không đúng");
    }

  };

  const submitEdit = () => {
    if (validate()) {
      handleSubmit().then((result) => {
        if (result && result.data) {
          closeDialog();
          removeSession();
          history.push("/auth/login");
        }
      })
    }
  }

  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;
    const { passwordNew, passwordOld } = password

    if (!passwordOld) {
      numberErr++;
      error.passwordOld = true;
      helperText.passwordOld = "Đây là trường bắt buộc";
    }

    if (!passwordNew) {
      numberErr++;
      error.passwordNew = true;
      helperText.passwordNew = "Đây là trường bắt buộc";
    }

    if (passwordNew && passwordNew.length < 6) {
      numberErr++;
      error.passwordNew = true;
      helperText.passwordNew = "Độ dài tối thiểu là 6";
    }
    if (passwordNew && passwordNew.length >= 6) {
      var regExpAZ = /[A-Z]/g;
      var regExpaz = /[a-z]/g;
      if (!regExpAZ.test(passwordNew) || !regExpaz.test(passwordNew)) {
        numberErr++;
        error.passwordNew = true;
        helperText.passwordNew = "Mật khẩu phải bao gồm cả chữ hoa và chữ thường";
      }
    }

    setErrorText(helperText);
    setErrorField(error);
    return !numberErr;
  };

  const onchangeInput = (fieldName, value) => {
    setPassword({ ...password, [fieldName]: value });
  };

  return (<Dialog
    open={openDialog}
    fullWidth
    onClose={closeDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      Thay đổi mật khẩu
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Mật khẩu cũ"
              inputProps={{
                value: password.passwordOld,
                onChange: (e) =>
                  onchangeInput("passwordOld", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.passwordOld ? errorText.passwordOld : ""}
              error={errorField.passwordOld}
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Mật khẩu mới"
              inputProps={{
                value: password.passwordNew,
                onChange: (e) =>
                  onchangeInput("passwordNew", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.passwordNew ? errorText.passwordNew : ""}
              error={errorField.passwordNew}
            />
          </GridItem>
        </GridContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={closeDialog} color="danger">
        Hủy bỏ
      </Button>
      <Button onClick={submitEdit} color="primary" autoFocus>
        Lưu
      </Button>
    </DialogActions>
  </Dialog>)
}