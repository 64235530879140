import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DataCategory from "components/DataCategory/DataCategory";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NetworkSelect from "components/NetworkSelect/NetworkSelect";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import fetchClient from "ultils/request";

export default function Tranfer({ selectedUsers, open, onSave, handleClose }) {
  const [selectedCategoriesId, setSelectedCategoriesId] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);

  const update = async () => {
    try {
      const usersId = [];
      for (let index = 0; index < selectedUsers.length; index++) {
        const element = selectedUsers[index];
        usersId.push(element.id);
      }
      const response = await fetchClient.post("/users/transfer", {
        usersId,
        dataCategoryIds: selectedCategoriesId,
        networkIds: selectedNetwork,
      });
      console.log(response.data);
      onSave();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Chuyển vùng dữ liệu, nhà mạng.
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={6}>
            <Typography>Người dùng được chuyển</Typography>
            <div
              style={{
                maxHeight: 350,
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <List dense>
                {selectedUsers &&
                  selectedUsers.map((user) => {
                    return (
                      <ListItem key={user.id}>
                        {/* <ListItemAvatar>
                        <Avatar>{user.firstName}</Avatar>
                      </ListItemAvatar> */}
                        <ListItemText
                          id={user.userName}
                          primary={<b>{user.userName}</b>}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <DataCategory
              onSelected={(value) => setSelectedCategoriesId(value)}
            />
            <NetworkSelect onSelected={(value) => setSelectedNetwork(value)} />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={update}
          color="primary"
          disabled={selectedCategoriesId.length == 0 && selectedNetwork == 0}
        >
          Lưu thông tin
        </Button>
        <Button onClick={handleClose} color="secondary">
          Hủy
        </Button>
      </DialogActions>
    </Dialog>
  );
}
