import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import {
  CheckCircle,
  Cancel,
  PhoneDisabled,
  DeviceUnknown,
  SkipNext,
} from "@material-ui/icons";
// core components
import Edit from "@material-ui/icons/Edit";
import DoneOutline from "@material-ui/icons/DoneOutline";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Chip, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { formatCurrency, formatPhoneNumber } from "../../../ultils/formater";
import moment from "moment";
import CardHeader from "components/Card/CardHeader";
import cx from "classnames";
import fetchClient from "../../../ultils/request";
import { userCallLogStatus } from "../../../variables/general";

import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CustomInput from "components/CustomInput/CustomInput.js";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteForever from "@material-ui/icons/DeleteForever";
import FormHelperText from "@material-ui/core/FormHelperText";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  buttonHug: {
    fontSize: "16px",
    marginBottom: "16px",
  },
  buttonHugAccess: {
    fontSize: "12px",
    marginBottom: "12px",
  },
  buttonHugIcon: {
    width: "24px",
    height: "24px",
  },
  mb3: {
    marginBottom: "8px",
  },
  mb4: {
    marginBottom: "12px",
    color: '#56af39',
  },

  primary: {
    backgroundColor: primaryColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.2)",
    },
  },
  info: {
    backgroundColor: infoColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: infoColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.2)",
    },
  },
  success: {
    backgroundColor: successColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: successColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2)",
    },
  },
  warning: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: warningColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.2)",
    },
  },
  danger: {
    backgroundColor: dangerColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)",
    },
  },
  rose: {
    backgroundColor: roseColor[0],
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: roseColor[0],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.2)",
    },
  },
  chipTextColor: {
    color: "white",
  },
  mt2: {
    marginTop: "8px",
  },
  mb1: {
    marginBottom: 4,
  },
  center: {
    justifyContent: "center",
  },
  textCenter: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: 'black',
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
};

const userCallLogText = ['Đang đợi', "Đồng ý", 'Không đồng ý mua', "Không nghe máy", 'Không liên lạc được', 'Bỏ qua', 'Khóa'];

const useStyles = makeStyles(styles);

export default function UserCallPage() {
  useEffect(() => {
    if (!customer.id && firstLoading) {
      fetchClient("/v1/customerdata")
        .then((respone) => {
          setCustomer(respone.data);
          setFirstLoading(false);
        })
        .catch(handerApiError);
    }
  });

  useEffect(() => {
    fechUserCallLogSummary();
  }, [])

  const [customer, setCustomer] = useState({});

  const [userCalLog, setUserCallLog] = useState({});

  const [startCallTime, setStartCallTime] = useState(moment());

  const [errorMessage, setErrorMessage] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [editModeCod, setEditModeCod] = useState(false);

  const [openLoading, setOpenLoading] = useState(false);

  const [firstLoading, setFirstLoading] = useState(true);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [dataBuy, setDataBuy] = useState({
    callStatusCode: '',
    area: ""
  }
  );

  const fechNextCustomer = async () => {
    try {
      setCustomer({});
      const customerReponse = await fetchClient("/v1/customerdata");
      const { data: customerNext } = customerReponse;
      setCustomer(customerNext);
    } catch (error) {
      handerApiError(error);
    }
  };

  const updateUserCallLog = async (logStatus, area = "") => {
    try {
      var productBuy = area != "" ? area : customer.productBuy;
      const customerDto = {
        ...customer,
        callStatusCode: logStatus,
        productBuy: productBuy,
      };
      const userCallLogDto = {
        logStatusCode: logStatus,
        startTime: startCallTime,
        endTime: moment(),
      };

      await fetchClient.post("/v1/customerdata", {
        customerDto,
        userCallLogDto,
      });

      fechNextCustomer();
      fechUserCallLogSummary();
      setStartCallTime(moment());

      handleCloseDialogConfirm();
    } catch (error) {
      handerApiError(error);
      handleCloseDialogConfirm();
    } finally {
      setTimeout(() => {
        setOpenLoading(false);
      }, 1000);
    }
  };

  const fechUserCallLogSummary = async () => {
    try {
      setUserCallLog({});
      const userCallLogReponse = await fetchClient.post(
        "v1/UserCallLogs/getsumary", {
      }
      );
      const { data } = userCallLogReponse;
      setUserCallLog(data);
    } catch (error) {
      handerApiError(error);
    }
  };

  const handerApiError = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const handleUpdateUserCallLog = async () => {
    setOpenLoading(true);
    await updateUserCallLog(dataBuy.callStatusCode, dataBuy.area);
  };
  const handleOpenConfirm = async (callStatusCode, area = "") => {
    setOpenDialogConfirm(true);
    setDataBuy({ ...dataBuy, callStatusCode: callStatusCode, area: area })
  };

  const handleEditAddress = (value) => {
    setCustomer({ ...customer, address: value });
  };
  const handleEditCod = (value) => {
    setCustomer({ ...customer, cod: value });
  };
  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
    setDataBuy({})
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12}>
        <Card className={cx(classes.mt2, classes.mb1)}>
          <CardHeader>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.mb3}
            >
              {userCalLog.userId ? (
                <>
                  <Chip
                    color="primary"
                    icon={<CheckCircle />}
                    label={
                      <>
                        <b>{userCalLog.accept}</b>
                      </>
                    }
                    className={cx(classes.success, classes.chipTextColor)}
                    size="small"
                  />
                  <Chip
                    color="primary"
                    icon={<Cancel />}
                    label={
                      <>
                        <b>{userCalLog.reject}</b>
                      </>
                    }
                    className={classes.danger}
                    size="small"
                  />
                  <Chip
                    color="primary"
                    icon={<PhoneDisabled />}
                    label={
                      <>
                        <b>{userCalLog.missCall}</b>
                      </>
                    }
                    className={classes.rose}
                    size="small"
                  />
                  <Chip
                    color="primary"
                    icon={<DeviceUnknown />}
                    label={
                      <>
                        <b>{userCalLog.wrongPhoneNumber}</b>
                      </>
                    }
                    className={classes.warning}
                    size="small"
                  />
                  <Chip
                    // color="primary"
                    icon={<SkipNext />}
                    label={
                      <>
                        <b>{userCalLog.ignore}</b>
                      </>
                    }
                    // className={classes.warning}
                    size="small"
                  />
                </>
              ) : (
                <Skeleton className={classes.mb3} width="100%" height={44} />
              )}
            </Grid>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
              {customer.id ? (
                <>
                  <Typography
                    align="center"
                    color="error"
                    variant="h5"
                    className={classes.mb3}
                  >
                    <a href={`tel:${customer.phoneNumber}`}>
                      <b> {formatPhoneNumber(customer.phoneNumber)} </b>
                      <span className="tennhamang">{customer.tenNhaMang}</span>
                    </a>
                  </Typography>
                  <Typography
                    align="center"
                    color="primary"
                    variant="subtitle1"
                    className={classes.mb3}
                  >
                    <b>{customer.fullName}</b>
                  </Typography>
                  {!editMode ? (
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="subtitle2"
                      className={classes.mb3}
                    >
                      {customer.address}
                      <Edit
                        color="secondary"
                        className={classes.icon}
                        onClick={() => setEditMode(true)}
                      />
                    </Typography>
                  ) : (
                    <GridContainer justify="center" alignItems="center">
                      <GridItem xs={11}>
                        <CustomInput
                          labelText="Địa chỉ"
                          inputProps={{
                            defaultValue: customer.address,
                            multiline: true,
                            onChange: (e) => handleEditAddress(e.target.value),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={1}>
                        <DoneOutline
                          color="secondary"
                          className={classes.icon}
                          onClick={() => setEditMode(false)}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="subtitle2"
                    className={classes.mb3}
                  >
                    {customer.product}
                  </Typography>

                  {!editModeCod ? (
                    <Typography
                      align="center"
                      color="colorTextPrimary"
                      variant="subtitle2"
                      className={classes.mb4}
                    >
                      {formatCurrency(customer.cod)}
                      <Edit
                        color="secondary"
                        className={classes.icon}
                        onClick={() => setEditModeCod(true)}
                      />
                    </Typography>
                  ) : (
                    <GridContainer justify="center" alignItems="center">
                      <GridItem xs={11}>
                        <CustomInput
                          labelText="cod"
                          inputProps={{
                            defaultValue: customer.cod,
                            multiline: true,
                            onChange: (e) => handleEditCod(e.target.value),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={1}>
                        <DoneOutline
                          color="secondary"
                          className={classes.icon}
                          onClick={() => setEditModeCod(false)}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                </>
              ) : (
                <>
                  <Skeleton className={classes.mb3} />
                  <Skeleton className={classes.mb3} />
                  <Skeleton className={classes.mb3} />
                  <Skeleton className={classes.mb3} />
                  <Skeleton className={classes.mb4} />
                </>
              )}
            </GridItem>
            <GridItem>
              <GridContainer>
                <GridItem xs={6}>
                  <Button
                    variant="contained"
                    color="success"
                    size='lg'
                    fullWidth
                    className={classes.buttonHugAccess}
                    // startIcon={<CheckCircle style={styles.buttonHugIcon} />}
                    onClick={() =>
                      handleOpenConfirm(
                        userCallLogStatus.accepted,
                        "Đồng ý nam"
                      )
                    }
                  >
                    Đồng ý nam
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <Button
                    variant="contained"
                    color="success"
                    size='lg'
                    fullWidth
                    className={classes.buttonHugAccess}
                    onClick={() =>
                      handleOpenConfirm(
                        userCallLogStatus.accepted,
                        "Đồng ý nữ"
                      )
                    }
                  >
                    Đồng ý nữ
                  </Button>
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    variant="contained"
                    color="success"
                    size='lg'
                    fullWidth
                    className={classes.buttonHugAccess}
                    onClick={() =>
                      handleOpenConfirm(
                        userCallLogStatus.accepted,
                        "Đồng ý Hà Nội"
                      )
                    }
                  >
                    Đồng ý Hà Nội
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12}>
              <Button
                variant="contained"
                color="danger"
                size='lg'
                fullWidth
                className={classes.buttonHug}
                startIcon={<Cancel style={styles.buttonHugIcon} />}
                onClick={() =>
                  handleOpenConfirm(userCallLogStatus.rejected)
                }
              >
                Không đồng ý mua
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Button
                variant="contained"
                color="rose"
                size='lg'
                fullWidth
                className={classes.buttonHug}
                startIcon={<PhoneDisabled style={styles.buttonHugIcon} />}
                onClick={() =>
                  handleOpenConfirm(userCallLogStatus.missCall)
                }
              >
                Không nghe máy
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Button
                variant="contained"
                color="warning"
                size='lg'
                fullWidth
                className={classes.buttonHug}
                startIcon={<DeviceUnknown style={styles.buttonHugIcon} />}
                onClick={() =>
                  handleOpenConfirm(userCallLogStatus.wrongPhoneNumber)
                }
              >
                Không liên lạc được
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Button
                variant="contained"
                size='lg'
                fullWidth
                className={classes.buttonHug}
                startIcon={<SkipNext style={styles.buttonHugIcon} />}
                onClick={() =>
                  handleOpenConfirm(userCallLogStatus.ignore)
                }
              >
                Bỏ qua
              </Button>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={10000}
          onClose={() => {
            setErrorMessage("");
          }}
        >
          <MuiAlert severity="warning">
            <b>{errorMessage}</b>
          </MuiAlert>
        </Snackbar>
      )}
      <DialogConfirm
        open={openDialogConfirm}
        handleClose={handleCloseDialogConfirm}
        handOK={handleUpdateUserCallLog}
        msg={userCallLogText[dataBuy.callStatusCode] + " " + dataBuy.area + "."}
        classes={classes}
      ></DialogConfirm>
    </GridContainer>
  );
}

const DialogConfirm = (props) => {
  const { open, handleClose, handOK, msg, classes } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title"></DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.textCenter}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.center}>
        <Button autoFocus onClick={handleClose} color="primary">
          Quay lại
        </Button>
        <Button autoFocus onClick={handOK} color="success">
          Gửi
        </Button>
      </DialogActions>
    </Dialog>
  );
};