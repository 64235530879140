import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import VisibilityIcon from '@material-ui/icons/Visibility';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import fetchClient from "../../ultils/request";


import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { getSession } from "../../ultils/appSession";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const stylesForm = {
  ...styles,
  errorText: {
    color: 'red'
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
};


const useStyles = makeStyles(stylesForm);
const snackbarDefault = {
  open: false,
  msg: '',
  status: 'success'
}

export default function DataCategoryPage() {
  const classes = useStyles();
  const currentSession = getSession();
  const roleAdminBase = currentSession.userRoles == "Admin" ? true : false;
  const [openDialog, setOpenDialog] = useState(false);
  const [textDialog, setTextDialog] = useState(false);
  const [errorText, setErrorText] = useState({});
  const [errorFile, setErrorFile] = useState({});
  const [snackbarData, setSnackbar] = useState(snackbarDefault);
  const [dataListActive, setDatalistActive] = useState([]);
  const [dataListVisible, setDatalistVisible] = useState([]);
  const [dataDialog, setDataDialog] = useState({
    CategoryName: '',
    IsDeleted: '',
    Id: '',
  });

  const [openLoading, setOpenLoading] = useState(false);
  const [openDialogTable, setOpenDialogTable] = useState(false);
  const [dataDialogTable, setDataDialogTable] = useState(false);
  const [nameCategory, setNameCategory] = useState('');

  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;
    const {
      CategoryName,
      IsDeleted,
    } = dataDialog;

    if (CategoryName === "") {
      numberErr++;
      error.CategoryName = true;
      helperText.CategoryName = "Đây là trường bắt buộc";
    }

    if (IsDeleted === "") {
      numberErr++;
      error.IsDeleted = true;
      helperText.IsDeleted = "Đây là trường bắt buộc";
    }

    setErrorText(helperText);
    setErrorFile(error);

    return !numberErr;
  };

  useEffect(() => {
    getListData();
  }, [])


  const handleOpenCreate = () => {
    setOpenDialog(true)
    setTextDialog('Thêm')
  }
  const handleOpenEdit = () => {
    setOpenDialog(true)
    setTextDialog('Sửa')

  }
  const handleCloseDialogTable = () => {
    setOpenDialogTable(false)
    setDataDialogTable([])
  }

  const getListData = async () => {
    try {
      const result = await fetchClient("/category/category-customer");
      if (result.data) {
        var dataTableActive = []
        var dataTableVisible = []
        result.data.forEach(e => {
          if (e.active) {
            dataTableActive.push([
              e.id,
              e.categoryName,
              e.waiting,
              e.accepted,
              e.rejected,
              <div>
                <div>
                  {e.missCall}
                </div>
                {e.missCall > 0 && !roleAdminBase && <button onClick={() => roleback200(e.id)}>
                  Chuyển {e.missCall >= 200 ? 200 : e.missCall} số về chưa gọi
              </button>}
              </div>,
              e.all,
              <RoundButtons
                onclickEdit={() => handleEditRowTable(e)}
                onclickView={() => handleViewRowTable(e.id, e.categoryName)}
              ></RoundButtons>
            ])
          } else {
            dataTableVisible.push([
              e.id,
              e.categoryName,
              e.waiting,
              e.accepted,
              e.rejected,
              e.missCall,
              e.all,
              <RoundButtons
                onclickEdit={() => handleEditRowTable(e)}
                onclickView={() => handleViewRowTable(e.id, e.categoryName)}
              ></RoundButtons>
            ])
          }


        });
        setDatalistActive(dataTableActive)
        setDatalistVisible(dataTableVisible)
        return result;
      }
    } catch (error) {
      setOpenLoading(false);
      console.log(error);
    }

  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDataDialog({
      CategoryName: "",
      Status: "",
      Id: '',
    });
    setErrorFile({})
    setErrorText({})
  };

  const handleEditRowTable = (e) => {
    handleOpenEdit()
    setDataDialog({ ...dataDialog, Id: e.id, CategoryName: e.categoryName, IsDeleted: !e.active })
  }
  const handleViewRowTable = (id, name) => {
    //todo Detail
    getListDataByIdCategory(id)
      .then((data) => {
        if (data) {
          setOpenDialogTable(true);
          setNameCategory(name)
        }
      })
  }

  const getListDataByIdCategory = async (id) => {
    try {
      const result = await fetchClient(`/category/detail/?id=${id}`);
      if (result.data) {
        var dataTable = []
        result.data.forEach(e => {
          dataTable.push([
            e.tenNhaMang,
            e.waiting,
            e.accepted,
            e.rejected,
            e.missCall,
            e.all,
          ])
        })

        setDataDialogTable(dataTable);
        return result;
      }
    } catch (error) {
      setOpenLoading(false);
      console.log(error);
    }

  }

  const handleSubmit = async () => {
    const formData = new FormData();
    if (validate()) {
      setOpenLoading(true);
      formData.append("CategoryName", dataDialog.CategoryName);
      formData.append("IsDeleted", Boolean(dataDialog.IsDeleted));
      if (dataDialog.Id !== "") {
        formData.append("Id", dataDialog.Id);
      }
      try {
        const result = await fetchClient.post(
          "/category/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result) {
          setOpenLoading(false);
          handleCloseDialog();
          setSnackbar({
            ...snackbarData,
            open: true,
            status: 'success',
            msg: 'Thành công'
          })
          getListData();
          return result;
        }
      } catch (error) {
        setOpenLoading(false);
        setSnackbar({
          ...snackbarData,
          open: true,
          status: 'error',
          msg: 'Thất bại'
        })
        console.log(error);
      }
    }
  };
  const roleback200 = async (id) => {
    setOpenLoading(true);
    try {
      const result = await fetchClient.post(
        `/category/roleback200?id=${id}`,
      );
      if (result) {
        setOpenLoading(false);
        setSnackbar({
          ...snackbarData,
          open: true,
          status: 'success',
          msg: 'Đã cập nhập thành công'
        })
        getListData();
        return result;
      }
    } catch (error) {
      setOpenLoading(false);
      setSnackbar({
        ...snackbarData,
        open: true,
        status: 'error',
        msg: 'Cập nhập thất bại'
      })
      console.log(error);
    }

  };


  const onchangeInput = (fieldName, value) => {
    setDataDialog({ ...dataDialog, [fieldName]: value });
  };

  const RoundButtons = (props) => {
    const { onclickEdit, onclickView } = props
    return (
      <>
        <Button
          round
          color='primary'
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={onclickView}
        >
          <VisibilityIcon className={classes.icon} />
        </Button>
        {!roleAdminBase && <Button
          round
          color='success'
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={onclickEdit}
        >
          <Edit className={classes.icon} />
        </Button>}
      </>
    );
  };
  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bảng danh mục hoạt động</h4>
          </CardHeader>
          <CardBody>
            {!roleAdminBase && <GridItem xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenCreate}
              >
                Thêm danh mục
          </Button>
            </GridItem>
            }
            <Table
              tableHead={[
                "Id",
                "Tên",
                "Số lượng chưa gọi",
                "Số lượng đồng ý",
                "Số lượng từ chối",
                "Số lượng không nhấc máy",
                "Tổng",
                "Hành động"
              ]}
              tableData={[...dataListActive]}
              customCellClasses={[classes.center, classes.center, classes.center, classes.center, classes.center, classes.left]}
              customClassesForCells={[1, 2, 3, 4, 5, 6, 7]}
              customHeadCellClasses={[classes.center, classes.center, classes.center, classes.center, classes.center, classes.left]}
              customHeadClassesForCells={[1, 2, 3, 4, 5, 6, 7]}
            />
          </CardBody>
        </Card>
      </GridItem>
      {!roleAdminBase && <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bảng danh mục không hoạt động</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "Id",
                "Tên",
                "Số lượng chưa gọi",
                "Số lượng đồng ý",
                "Số lượng từ chối",
                "Số lượng không nhấc máy",
                "Tổng",
              ]}
              tableData={[...dataListVisible]}
              customCellClasses={[classes.center, classes.center, classes.center, classes.center, classes.center, classes.center]}
              customClassesForCells={[1, 2, 3, 4, 5, 6]}
              customHeadCellClasses={[classes.center, classes.center, classes.center, classes.center, classes.center, classes.center]}
              customHeadClassesForCells={[1, 2, 3, 4, 5, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>}

      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {textDialog}  danh mục
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={8}>
                <CustomInput
                  labelText="Tên doanh mục"
                  inputProps={{
                    defaultValue: dataDialog.CategoryName,
                    onChange: (e) =>
                      onchangeInput("CategoryName", e.target.value),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  helperText={errorText.CategoryName ? errorText.CategoryName : ""}
                  error={errorFile.CategoryName}
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="Role" className={classes.selectLabel}>
                    Trạng thái
                    </InputLabel>
                  <Select
                    defaultValue={dataDialog.IsDeleted}
                    onChange={(e) => onchangeInput("IsDeleted", e.target.value)}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    error={errorFile.IsDeleted}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={true}
                    >
                      Không hoạt động
                      </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={false}
                    >
                      Hoạt động
                      </MenuItem>
                  </Select>
                  <FormHelperText className={classes.errorText}>
                    {errorText.IsDeleted ? errorText.IsDeleted : ""}
                  </FormHelperText>
                </FormControl>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="danger">
            Hủy bỏ
            </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {textDialog}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogTable openDialog={openDialogTable}
        handleCloseDialog={handleCloseDialogTable}
        data={dataDialogTable}
        classes={classes}
        name={nameCategory}

      ></DialogTable>
      <Snackbar
        open={snackbarData.open}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbar(snackbarDefault)
        }}
      >
        <MuiAlert severity={snackbarData.status}>{snackbarData.msg}</MuiAlert>
      </Snackbar>

    </GridContainer>
  );
}

const DialogTable = (props) => {
  const { openDialog, handleCloseDialog, name, data, classes } = props
  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Chi tiết danh mục {name}
      </DialogTitle>
      <DialogContent>
        <GridContainer justify="center">
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Bảng chi tiết danh mục</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "Tên nhà mạng",
                  "Số lượng chưa gọi",
                  "Số lượng đồng ý",
                  "Số lượng từ chối",
                  "Số lượng không nhấc máy",
                  "Tổng",
                ]}
                tableData={data}
                customCellClasses={[classes.center, classes.center, classes.center, classes.center, classes.center, classes.center]}
                customClassesForCells={[1, 2, 3, 4, 5, 6]}
                customHeadCellClasses={[classes.center, classes.center, classes.center, classes.center, classes.center, classes.center]}
                customHeadClassesForCells={[1, 2, 3, 4, 5, 6]}
              />
            </CardBody>
          </Card>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialog} color="danger">
          Đóng
      </Button>
      </DialogActions>
    </Dialog>
  )
}