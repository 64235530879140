import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { networkList } from "../../variables/general";

export default function NetworkSelect({ onSelected }) {
  const [selectedNetword, setselectedNetword] = useState([]);

  const handleChange = (event) => {
    setselectedNetword(event.target.value);
    onSelected(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Chọn nhà mạng</InputLabel>
      <Select
        id="network-select"
        onChange={handleChange}
        multiple
        value={selectedNetword}
      >
        {networkList.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
