import React, { useEffect, useState } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";

// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Date
import Datetime from "react-datetime";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import fetchClient from "../../ultils/request";

const stylesForm = {
  ...styles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  dialogContent: {
    height: 390,
    maxHeight: "70%",
  },
  ml20: {
    marginLeft: 20,
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
};

const useStyles = makeStyles(stylesForm);
const valueAll = 1000;

const convertToArrBuffer = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export default function DataPage() {
  const [data, setData] = useState([]);
  const [listDataNumberPage, setDataListDataNumberPage] = useState([]);
  const [listStatus, setDataListStatus] = useState([]);
  const [listCategory, setCategoryList] = useState([]);
  const [totalPage, setTotalPage] = useState([]);
  const [listIUsers, setListIUsers] = useState([]);
  const [dataSearch, setDataSearch] = useState({
    numberOfPage: "10",
    page: "1",
    dataCategoryCode: [],
    status: [],
    DataAuto: "",
    auto: 1,
  });
  const [openLoading, setOpenLoading] = useState(false);

  const [openCreate, setOpenCreate] = useState(false);

  const classes = useStyles();

  var statusCustomer = [
    {
      key: 0,
      value: "Chưa gọi",
    },
    {
      key: 1,
      value: "Đồng ý",
    },
    {
      key: 2,
      value: "Từ chối",
    },
    {
      key: 3,
      value: "Không bắt máy",
    },
    {
      key: 4,
      value: "Số điện thoại sai",
    },
    {
      key: 5,
      value: "Bỏ qua",
    },
    {
      key: 6,
      value: "Khóa",
    },
  ];

  const handleSubmitData = async () => {
    try {
      const formData = createFormDataSearch();
      const result = await fetchClient.post("/users/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result) {
        return result.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleExportDataSeach = async () => {
    setOpenLoading(true);
    try {
      const formData = createFormDataSearch();
      const result = await fetchClient.post("/users/export", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result) {
        var blob = new Blob([convertToArrBuffer(atob(result.data))], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const fileName =
          "TeleSale_Customer_Export_" +
          moment(Date.now()).format("MM-DD-YYYY_HH-mm-ss");
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      setOpenLoading(false);
    } catch (e) {
      console.log(e);
      setOpenLoading(false);
    }
  };

  const createFormDataSearch = () => {
    const formData = new FormData();
    if (dataSearch.numberOfPage && Number(dataSearch.numberOfPage)) {
      formData.append("NumberOfPage", Number(dataSearch.numberOfPage));
    }
    if (dataSearch.page && Number(dataSearch.page)) {
      formData.append("Page", Number(dataSearch.page));
    }
    if (
      dataSearch.dataCategoryCode &&
      dataSearch.dataCategoryCode.length > 0 &&
      dataSearch.dataCategoryCode.indexOf(valueAll) == -1
    ) {
      formData.append("DataCategoryCode", dataSearch.dataCategoryCode);
    }
    if (
      dataSearch.status &&
      dataSearch.status.length > 0 &&
      dataSearch.status.indexOf(valueAll) == -1
    ) {
      formData.append("Status", dataSearch.status);
    }
    if (data.PhoneNumber != "" && dataSearch.auto == 1) {
      formData.append("PhoneNumber", dataSearch.DataAuto);
    }
    if (dataSearch.FullName != "" && dataSearch.auto == 2) {
      formData.append("FullName", dataSearch.DataAuto);
    }
    return formData;
  };

  const getDataCategory = async () => {
    try {
      const result = await fetchClient.get("/category/categoryActive/");
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDataListIUsers = async () => {
    try {
      const result = await fetchClient.get("/users/iUser");
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseDialog = () => {
    setOpenCreate(false);
  };

  const handleSearch = () => {
    setOpenLoading(true);
    handleSubmitData().then((data) => {
      var dataTable = [];
      if (data) {
        dataTable = data.data;
        setTotalPage(data.totalPage);
        var dataMapTable = [];
        dataTable.forEach((e) => {
          dataMapTable.push([
            e.id,
            e.fullName,
            e.phoneNumber,
            e.address,
            e.productBuy,
            e.dataCategoryName,
            e.product,
            e.cod,
            statusCustomer[e.callStatusCode]?.value,
            e.nameUpdate ? e.nameUpdate : "---",
            e.lastModifiedOn ? moment(e.lastModifiedOn).format("MM-DD-YYYY HH:mm:ss") : "---",
          ]);
        });
        setData(dataMapTable);
      }
      setOpenLoading(false);
    });
  };

  const handleCategoryList = () => {
    getDataCategory().then((data) => {
      if (data) {
        var dataResult = data.data || [];
        var listCategory = [];
        dataResult.map((data) => {
          listCategory.push({
            key: data.id,
            value: data.categoryName,
          });
        });
        setCategoryList(listCategory);
      }
    });
  };

  const handIUserList = () => {
    getDataListIUsers().then((data) => {
      if (data) {
        var dataResult = data.data || [];
        var listIUsers = [];
        dataResult.map((data) => {
          listIUsers.push({
            key: data.id,
            value: data.userName,
          });
        });
        setListIUsers(listIUsers);
      }
    });
  };

  useEffect(() => {
    setDataListDataNumberPage([10, 20, 50, 100, 5000, 1000]);
    setDataListStatus(statusCustomer);
    handleCategoryList();
    handIUserList();
    handleSearch();
  }, []);

  const onchangeInput = (fieldName, value) => {
    setDataSearch({ ...dataSearch, [fieldName]: value });
  };

  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="numberOfPage"
                    className={classes.selectLabel}
                  >
                    Hiển thị
                  </InputLabel>
                  <Select
                    value={Number(dataSearch.numberOfPage)}
                    onChange={(e) =>
                      onchangeInput("numberOfPage", e.target.value)
                    }
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    {listDataNumberPage &&
                      listDataNumberPage.map((e, i) => {
                        return (
                          <MenuItem
                            key={i + i}
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={e}
                          >
                            {e}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={5} md={4}>
                <CustomInput
                  labelText="Trang"
                  inputProps={{
                    defaultValue: dataSearch.page,
                    onChange: (e) => onchangeInput("page", e.target.value),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        /{totalPage}
                      </InputAdornment>
                    ),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="dataCategoryCode"
                        className={classes.selectLabel}
                      >
                        Loại data
                      </InputLabel>
                      <Select
                        multiple
                        value={dataSearch.dataCategoryCode}
                        onChange={(e) =>
                          onchangeInput("dataCategoryCode", e.target.value)
                        }
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Loại data
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={valueAll}
                        >
                          Tất cả
                        </MenuItem>
                        {listCategory &&
                          listCategory.map((e, i) => {
                            return (
                              <MenuItem
                                key={i + i}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                                value={e.key}
                              >
                                {e.value}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="status"
                        className={classes.selectLabel}
                      >
                        Trạng Thái
                      </InputLabel>
                      <Select
                        multiple
                        value={dataSearch.status}
                        onChange={(e) =>
                          onchangeInput("status", e.target.value)
                        }
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Trạng Thái
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={valueAll}
                        >
                          Tất cả
                        </MenuItem>
                        {listStatus &&
                          listStatus.map((e, i) => {
                            return (
                              <MenuItem
                                key={i + i}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                                value={e.key}
                              >
                                {e.value}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="numberOfPage"
                            className={classes.selectLabel}
                          >
                            Tìm kiếm
                          </InputLabel>
                          <Select
                            value={Number(dataSearch.auto)}
                            onChange={(e) =>
                              onchangeInput("auto", e.target.value)
                            }
                            MenuProps={{ className: classes.selectMenu }}
                            classes={{ select: classes.select }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              value={1}
                            >
                              Số điện thoại
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              value={2}
                            >
                              Tên khách hàng
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText=""
                          inputProps={{
                            defaultValue: dataSearch.DataAuto,
                            onChange: (e) =>
                              onchangeInput("DataAuto", e.target.value),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={6} justify="flex-start" container>
        <Button color="primary" onClick={handleExportDataSeach}>
          Tạo báo cáo
        </Button>
        <Button
          color="success"
          className={classes.ml20}
          onClick={() => setOpenCreate(true)}
        >
          Tạo báo cáo chi tiết
        </Button>
      </GridItem>
      <GridItem xs={6} justify="flex-end" container>
        <Button color="primary" onClick={handleSearch}>
          Tìm kiếm
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bảng dữ liệu</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "Mã",
                "Họ Tên",
                "SĐT",
                "Địa Chỉ",
                "Tên sản phẩm đã mua",
                "Danh mục sản phẩm",
                "Tên Sản Phẩm",
                "COD",
                "Trạng thái",
                "Tên người gọi",
                "Thời gian gọi",
              ]}
              tableData={[...data]}
            />
          </CardBody>
        </Card>
      </GridItem>
      <DialogExport
        openCreate={openCreate}
        listCategory={listCategory}
        listStatus={listStatus}
        handleCloseDialog={handleCloseDialog}
        classes={classes}
        listIUsers={listIUsers}
        setOpenLoading={setOpenLoading}
      />
    </GridContainer>
  );
}

const DialogExport = (props) => {
  const {
    openCreate,
    listCategory,
    listStatus,
    handleCloseDialog,
    classes,
    listIUsers,
    setOpenLoading,
  } = props;

  const [dataExport, setDataExport] = useState({
    dataCategoryCode: [],
    status: [],
    TimeStart: Date.now(),
    TimeEnd: Date.now(),
    IUserId: [],
  });

  const closeDialog = () => {
    setDataExport({
      dataCategoryCode: [],
      status: [],
      TimeStart: Date.now(),
      TimeEnd: Date.now(),
      IUserId: [],
    });
    handleCloseDialog();
  };

  const createFormDataExport = () => {
    const formData = new FormData();
    if (
      dataExport.dataCategoryCode &&
      dataExport.dataCategoryCode.length > 0 &&
      dataExport.dataCategoryCode.indexOf(valueAll) == -1
    ) {
      formData.append("DataCategoryCode", dataExport.dataCategoryCode);
    }
    if (
      dataExport.status &&
      dataExport.status.length > 0 &&
      dataExport.status.indexOf(valueAll) == -1
    ) {
      formData.append("Status", dataExport.status);
    }
    if (
      dataExport.IUserId &&
      dataExport.IUserId.length > 0 &&
      dataExport.IUserId.indexOf(valueAll) == -1
    ) {
      formData.append("IUserId", dataExport.IUserId);
    }
    if (dataExport.TimeStart != "" && dataExport.TimeStart != "undefine") {
      formData.append(
        "TimeStart",
        moment(dataExport.TimeStart).format("MM-DD-YYYY HH:mm:ss")
      );
    }
    if (dataExport.TimeEnd != "" && dataExport.TimeEnd != "undefine") {
      formData.append(
        "TimeEnd",
        moment(dataExport.TimeEnd).format("MM-DD-YYYY HH:mm:ss")
      );
    }
    return formData;
  };

  const handleExportDataExport = async () => {
    try {
      setOpenLoading(true);
      const formData = createFormDataExport();
      const result = await fetchClient.post("/users/export", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result) {
        var blob = new Blob([convertToArrBuffer(atob(result.data))], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const fileName =
          "TeleSale_Customer_Export_" +
          moment(Date.now()).format("MM-DD-YYYY_HH-mm-ss");
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      setOpenLoading(false);
    } catch (e) {
      console.log(e);
      setOpenLoading(false);
    }
  };
  const onchangeInputExport = (fieldName, value) => {
    setDataExport({ ...dataExport, [fieldName]: value });
  };

  return (
    <Dialog
      open={openCreate}
      fullWidth
      maxWidth="md"
      onClose={() => closeDialog()}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Tạo báo cáo chi tiết"}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <InputLabel className={classes.label}>
                    Thời gian bắt đầu
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      dateFormat={"YYYY/MM/DD"}
                      timeFormat={"HH:mm:ss"}
                      defaultValue={dataExport.TimeStart}
                      inputProps={{ placeholder: "Thời gian bắt đầu" }}
                      onChange={(e) => onchangeInputExport("TimeStart", e._d)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <InputLabel className={classes.label}>
                    Thời gian kết thúc
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      dateFormat={"YYYY/MM/DD"}
                      timeFormat={"HH:mm:ss"}
                      defaultValue={dataExport.TimeEnd}
                      inputProps={{ placeholder: "Thời gian kết thúc" }}
                      onChange={(e) => onchangeInputExport("TimeEnd", e._d)}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={10} md={10}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="dataCategoryCode"
                      className={classes.selectLabel}
                    >
                      Loại data
                    </InputLabel>
                    <Select
                      multiple
                      value={dataExport.dataCategoryCode}
                      onChange={(e) =>
                        onchangeInputExport("dataCategoryCode", e.target.value)
                      }
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Loại data
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={valueAll}
                      >
                        Tất cả
                      </MenuItem>
                      {listCategory &&
                        listCategory.map((e, i) => {
                          return (
                            <MenuItem
                              key={i + i}
                              classes={{
                                root: classes.selectMenuItem,
                                selected:
                                  classes.selectMenuItemSelectedMultiple,
                              }}
                              value={e.key}
                            >
                              {e.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="status"
                      className={classes.selectLabel}
                    >
                      Trạng Thái
                    </InputLabel>
                    <Select
                      multiple
                      value={dataExport.status}
                      onChange={(e) =>
                        onchangeInputExport("status", e.target.value)
                      }
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Trạng Thái
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={valueAll}
                      >
                        Tất cả
                      </MenuItem>
                      {listStatus &&
                        listStatus.map((e, i) => {
                          return (
                            <MenuItem
                              key={i + i}
                              classes={{
                                root: classes.selectMenuItem,
                                selected:
                                  classes.selectMenuItemSelectedMultiple,
                              }}
                              value={e.key}
                            >
                              {e.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={10} md={10}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="status" className={classes.selectLabel}>
                  Tài khoản
                </InputLabel>
                <Select
                  multiple
                  value={dataExport.IUserId}
                  onChange={(e) =>
                    onchangeInputExport("IUserId", e.target.value)
                  }
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Tài khoản
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple,
                    }}
                    value={valueAll}
                  >
                    Tất cả
                  </MenuItem>
                  {listIUsers &&
                    listIUsers.map((e, i) => {
                      return (
                        <MenuItem
                          key={i + i}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={e.key}
                        >
                          {e.value}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDialog} color="danger">
          Hủy bỏ
        </Button>
        <Button onClick={handleExportDataExport} color="primary" autoFocus>
          Tạo
        </Button>
      </DialogActions>
    </Dialog>
  );
};
