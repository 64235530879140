import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";
import OrderPage from "views/Pages/OrderPage";
import UserPage from "views/Pages/UserPage";
import DataPage from "views/Pages/DataPage";
import ImportPage from "views/Pages/ImportPage";
import CheckCustomerInfoPage from "views/Pages/CheckCustomerInfoPage";
import DataCategoryPage from "views/Pages/DataCategoryPage";
import UserCallPage from "views/Pages/UserPage/UserCallPage";
import UserCallHistoryPage from "views/Pages/UserPage/UserCallHistoryPage";
import Home from "views/Pages/Home";
import TeamPage from "views/Pages/TeamPage";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  ShoppingBasket,
  SupervisedUserCircle,
  Backup,
  Storage,
  Category,
  Group,
  Check,
} from "@material-ui/icons";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Tổng quan",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Home,
    layout: "/admin",
  },
  {
    path: "/don-hang",
    name: "Đơn hàng",
    icon: ShoppingBasket,
    component: OrderPage,
    layout: "/admin",
  },
  {
    path: "/nhan-vien",
    name: "Nhân viên",
    icon: SupervisedUserCircle,
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/team",
    name: "Quản lý nhóm",
    icon: Group,
    component: TeamPage,
    layout: "/admin",
  },
  {
    path: "/quan-ly-du-lieu",
    name: "Quản lý dữ liệu",
    icon: Storage,
    component: DataPage,
    layout: "/admin",
  },
  {
    path: "/nhap-du-lieu",
    name: "Nhập dữ liệu",
    icon: Backup,
    component: ImportPage,
    layout: "/admin",
  },
  {
    path: "/kiem-tra-thong-tin-khach-hang",
    name: "Kiểm tra KH",
    icon: Check,
    component: CheckCustomerInfoPage,
    layout: "/admin",
  },
  {
    path: "/danh-muc-du-lieu",
    name: "Danh mục dữ liệu",
    icon: Category,
    component: DataCategoryPage,
    layout: "/admin",
  },
  {
    path: "/goi",
    name: "Gọi khách hàng",
    icon: Category,
    component: UserCallPage,
    layout: "/user",
  },
  {
    path: "/lich-su-goi",
    name: "Lịch sử gọi",
    icon: Category,
    component: UserCallHistoryPage,
    layout: "/user",
  },
  {
    path: "/login-page",
    name: "Đăng nhập",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/lock-screen-page",
    name: "Màn hình khóa",
    rtlName: "اقفل الشاشة",
    mini: "LS",
    rtlMini: "هذاع",
    component: LockScreenPage,
    layout: "/auth",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
];

export default dashRoutes;
