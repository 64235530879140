import axios from "axios";
import { removeSession } from "./appSession";

export const apiCallStatusCode = {
  UNKNOWN: -1,
  NETWORK_ERROR: 0,
  REQUEST_TIME_OUT: 1,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  PAGE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVER_UNAVAILABLE: 503,
};

// Todo translate to Japaness
export const apiErrorMesageMapping = [
  {
    statusCode: apiCallStatusCode.UNKNOWN,
    message: "Xảy ra lỗi không xác định.",
  },
  {
    statusCode: apiCallStatusCode.NETWORK_ERROR,
    message: "Mất kết nối đến máy chủ vui lòng kiểm tra.",
  },
  {
    statusCode: apiCallStatusCode.REQUEST_TIME_OUT,
    message: "Máy chủ không có phản hồi vui lòng thử lại.",
  },
  {
    statusCode: apiCallStatusCode.UNAUTHORIZED,
    message: "Phiên làm việc hết hạn, vui lòng Đăng nhập lại",
  },
  {
    statusCode: apiCallStatusCode.FORBIDDEN,
    message: "Bạn không có quyền thao tác.",
  },
  {
    statusCode: apiCallStatusCode.PAGE_NOT_FOUND,
    message: "Không tìm thấy trang.",
  },
  {
    statusCode: apiCallStatusCode.INTERNAL_SERVER_ERROR,
    message: "Có lỗi xảy ra phía máy chủ.",
  },
  {
    statusCode: apiCallStatusCode.SERVER_UNAVAILABLE,
    message: "Máy chủ không sẵn sàng.",
  },
];

export const getApiErrorMessage = (err) => {
  let statusCode = apiCallStatusCode.UNKNOWN;
  if (err.response) {
    // Request made and server responded (5xx, 4xx)
    statusCode = err.response.status;
    if (statusCode === 403) {
      setTimeout(() => {
        removeSession();
        window.location.href = "/auth/login-page"
      }, 1000)
      return {
        statusCode,
        message: "Phiên làm việc hết hạn, vui lòng Đăng nhập lại",
      };
    }

    // Error from back-end
    if (statusCode === 500 && err.response.data && err.response.data.Failed) {
      return {
        statusCode,
        message: err.response.data.Message || err.response.data.message,
      };
    } else {
      return {
        statusCode,
        message: err.response.data.message,
      };
    }
  } else if (err.request) {
    // The request was made but no response was received
    if (err.message === "Network Error")
      statusCode = apiCallStatusCode.NETWORK_ERROR;
    if (err.message.toLocaleLowerCase().startsWith("timeout of"))
      statusCode = apiCallStatusCode.REQUEST_TIME_OUT;
  } else {
    // Unhandle exception.
    statusCode = apiCallStatusCode.UNKNOWN;
  }
  const apiError = apiErrorMesageMapping.filter(
    (apiErr) => apiErr.statusCode === statusCode
  )[0];

  return apiError;
};

const isLogininRequest = (url) => url && url.endsWith("/identity/token");

const getJSON = function (url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'json';

  xhr.onload = function () {

    var status = xhr.status;
    if (status == 200) {
      callback(null, xhr.response);
    } else {
      callback(status);
    }
  };

  xhr.send();
};
getJSON('https://api.ipify.org?format=json', function (err, data) {
  if (err != null) {
    console.error(err);
  } else {
    localStorage.setItem("myId", data.ip);
  }
});

const fetchClient = (url) => {
  const isDevelopmentEnv = process.env.NODE_ENV === "development";
  const defaultOptions = {
    url,
    method: "get",
    accept: "*/*",
    headers: {
      "Content-Type": "application/json",
    },
    // Todo replace with production enpoint
    baseURL: isDevelopmentEnv
      ? "https://localhost:44377/api/"
      : "http://tuvanbanhang.com/api/",
  };
  // Create instance
  const instance = axios.create(defaultOptions);
  // instance.defaults.timeout = 900;

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    if (!isLogininRequest(config.url)) {
      const token = localStorage.getItem("jwToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    const myIp = localStorage.getItem("myId");
    config.headers.myIp = myIp;
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      // Clear error when the next call api success
      return response;
    },
    (err) => {
      return Promise.reject(getApiErrorMessage(err));
    }
  );

  return instance;
};

export default fetchClient();
