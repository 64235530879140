import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { checkAuth } from "../../ultils/appSession";

/* eslint-disable react/jsx-props-no-spreading */
function PrivateRouter({ component: Component, ...rest }) {
  const role = localStorage.getItem("userRoles")
  const isAdmin = role && role.indexOf('Admin') != -1
  const roleCorrectFlowPath = rest.role
  let failRole = false;
  if (!isAdmin && roleCorrectFlowPath == 'Admin') {
    failRole = true
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!checkAuth()) {
          return <Redirect to={{ pathname: "/auth/login-page" }} />
        }
        if (failRole) {
          return <Redirect to={{ pathname: "/user/goi" }} />
        }
        return <Component {...props} />
      }

      }
    />
  );
}

PrivateRouter.propTypes = {
  component: PropTypes.element,
  softToken: PropTypes.string,
};

PrivateRouter.defaultProps = {
  component: null,
  softToken: "",
};
export default PrivateRouter;
