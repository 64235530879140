import React from "react";
import cx from "classnames";

import routes from "routes.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import PrivateRouter from "components/PrivateRouter/PrivateRouter";
import AppBar from "components/AppBar/AppBar";
import styles from "assets/jss/material-dashboard-pro-react/layouts/userStyle.js";
import { Container, Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function User(props) {
  // styles
  const classes = useStyles();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/user") {
        return (
          <PrivateRouter
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            role="User"
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <>
      <AppBar />
      <Container maxWidth="sm">
        <Grid>{getRoutes(routes)}</Grid>
      </Container>
    </>
  );
}
