import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import queryString from 'query-string';

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";



import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import fetchClient from "../../ultils/request";
import { getSession } from "../../ultils/appSession";
const stylesForm = {
  ...styles,
  ...styles2,
  errorText: {
    color: 'red'
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
  maxWidth200: {
    maxWidth: 200,
  },
};

const MobileNetworkSupplierList = [
  {
    key: 1,
    value: "VIETTEL",
  },
  {
    key: 2,
    value: "VINAPHONE",
  },
  {
    key: 3,
    value: "MOBIPHONE",
  },
  {
    key: 4,
    value: "VIETNAMOBILE",
  },
  {
    key: 5,
    value: "ITELECOM",
  },
  {
    key: 6,
    value: "NHÀ MẠNG KHÁC",
  },
];

const useStyles = makeStyles(stylesForm);
const snackbarDefault = {
  open: false,
  msg: '',
  status: 'success'
}

export default function TeamPage() {
  const classes = useStyles();
  const [openLoading, setOpenLoading] = useState(false);
  const [snackbarData, setSnackbar] = useState(snackbarDefault);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataMyTeam, setDataMyTeam] = useState([]);
  const [dataOutTeam, setDataOutTeam] = useState([]);
  const [textDialog, setTextDialog] = useState(false);
  const [role, setRole] = useState("");
  const [dataDialog, setDataDialog] = useState({
    CategoryName: '',
    TeamLead: '',
    Id: '',
  });

  const [listCategory, setCategoryList] = useState([]);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [idDelete, setIdDelete] = useState();

  const [openCreate, setOpenCreate] = useState(false);
  const [idEdit, setIdEdit] = useState(false);

  useEffect(() => {
    const currentSession = getSession();
    if (currentSession.userRoles !== "Admin") {
      getListData();
    } else {
      handleCategoryList();
    }
    setRole(currentSession.userRoles)
  }, [])

  useEffect(() => {
    if (listCategory.length > 0) {
      handleReloadTable();
    }
  }, [listCategory])

  const handleReloadTable = () => {
    getDataOutTeam();
    getDataMyTeam();
  }


  const getDataCategory = async () => {
    try {
      const result = await fetchClient.get("/category/categoryActive/");
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCategoryList = () => {
    getDataCategory().then((data) => {
      if (data) {
        var dataResult = data.data || [];
        var listCategory = [];
        dataResult.map((data) => {
          listCategory.push({
            key: data.id.toString(),
            value: data.categoryName,
          });
        });
        setCategoryList(listCategory);
      }
    });
  };

  const RoundButtons = (props) => {
    const { onclickEdit, onclickDelete } = props
    return (
      <>
        <Button
          round
          color='success'
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={onclickEdit}
        >
          <Edit className={classes.icon} />
        </Button>
        <Button
          round
          color='danger'
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={onclickDelete}
        >
          <Delete className={classes.icon} />
        </Button>
      </>
    );
  };

  const getListData = async () => {
    try {
      const result = await fetchClient("/team/");
      if (result && result.data) {
        var dataTable = []
        result.data.forEach(e => {
          dataTable.push([
            e.name,
            e.teamLeadName,
            e.numberMember,
            <RoundButtons
              onclickEdit={() => handleEditRowTable(e)}
              onclickDelete={() => handleDeleteRowTable(e.teamId)}
            ></RoundButtons>
          ])
        });
        setDataList(dataTable)
        return result;
      }
    } catch (error) {
      setOpenLoading(false);
      console.log(error);
    }

  }

  const handleEditRowTable = (value) => {
    handleOpenEdit();
    setDataDialog(value);
  }

  const handleDeleteRowTable = (id) => {
    setOpenDialogConfirm(true);
    setIdDelete(id);
  }


  const handleDelete = async () => {
    try {
      setOpenLoading(true);
      const result = await fetchClient.delete(`/team/?id=${idDelete}`);
      if (result.status == 200) {
        setOpenLoading(false);
        getListData();
        setSnackbar({
          ...snackbarData,
          open: true,
          status: 'success',
          msg: 'Thành công'
        })
        handleCloseDialogConfirm();
      } else {
        setOpenLoading(false);
        setSnackbar({
          ...snackbarData,
          open: true,
          status: 'error',
          msg: 'Không thành công'
        })
      }
    } catch (e) {
      setOpenLoading(false);
      console.log(e);
    }
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };


  const handleOpenCreate = () => {
    setOpenDialog(true);
    setTextDialog('Thêm');
  }
  const handleOpenEdit = () => {
    setOpenDialog(true);
    setTextDialog('Sửa');

  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDataDialog({
      CategoryName: "",
      Status: "",
      Id: '',
    });
  };

  const getUserListMyTeam = async () => {
    return await fetchClient.get("/users/iUser-my-team");
  };

  const getDataMyTeam = () => {
    getUserListMyTeam().then((result) => {
      var dataTable = result.data;
      if (!dataTable) {
        setSnackbar({
          ...snackbarData,
          open: true,
          status: 'error',
          msg: 'Bạn đang không quản lý nhóm nào'
        })
        return;
      }
      var dataMapTable = [];
      dataTable && dataTable.forEach((e) => {
        dataMapTable.push([
          e.userName,
          e.email,
          e.mobileNetworkSupplier,
          displayDataCategory(e.dataCategory),
          e.isActive ?
            <p style={{ color: 'blue' }}>Đang hoạt động</p> : <p style={{ color: 'red' }}>Dừng hoạt động</p>,
          <>
            <Button color='success' className={classes.actionButton + " " + classes.actionButtonRound} onClick={() => editUser(e.userId)} >
              <Edit></Edit>
            </Button>
            <Button color='danger' onClick={() => deleteUser(e.userId)} className={classes.actionButton + " " + classes.actionButtonRound} >
              <Delete></Delete>
            </Button>
          </>
        ]);
      })
      setDataMyTeam(dataMapTable)
    })
  }


  const displayDataCategory = (dataCategoryListStr) => {
    if (!dataCategoryListStr) return null;
    const datacategoryList = dataCategoryListStr.split(",");
    let listCategoryName = [];
    for (let index = 0; index < datacategoryList.length; index++) {
      const categoryId = datacategoryList[index];
      const category = listCategory.filter((item) => item.key == categoryId);
      if (category && category.length > 0) {
        listCategoryName.push(category[0].value);
      } else {
        listCategoryName.push(`(${categoryId})`);
      }
    }

    return listCategoryName.join(", ");
  };
  const editUser = (value) => {
    // Todo Edit
    setIdEdit(value)
    setOpenCreate(true)
  }

  const callDeleteUser = async (id) => {
    return await fetchClient.post(`/team/delete/?userId=${id}`);
  };

  const deleteUser = (id) => {
    callDeleteUser(id).then((result) => {
      handleReloadTable();
    })
  }

  const getUserListOutTeam = async () => {
    return await fetchClient.get("/users/list-user-role/?roleName=Basic");
  };

  const callAddUser = async (id) => {
    try {
      return await fetchClient.post(`/team/add/?userId=${id}`);
    } catch (error) {
      setSnackbar({
        ...snackbarData,
        open: true,
        status: 'error',
        msg: 'Bạn không thể thêm tài khoản này vào nhóm'
      })
    }
  };

  const addUser = (id) => {
    callAddUser(id).then((result) => {
      if (result) {
        handleReloadTable();

      }
    })
  }

  const getDataOutTeam = () => {
    getUserListOutTeam().then((result) => {
      if (result) {
        var dataTable = result.data;
        var dataMapTable = [];
        dataTable && dataTable.forEach((e) => {
          dataMapTable.push([
            e.userName,
            e.email,
            e.mobileNetworkSupplier,
            displayDataCategory(e.dataCategory),
            e.isActive ?
              <p style={{ color: 'blue' }}>Đang hoạt động</p> : <p style={{ color: 'red' }}>Dừng hoạt động</p>,
            <Button color='success' onClick={() => addUser(e.userId)} className={classes.actionButton + " " + classes.actionButtonRound}>
              Thêm vào nhóm
            </Button>
          ]);
        })
        setDataOutTeam(dataMapTable)
      }
    })
  }
  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {role !== "Admin" && <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bảng danh sách nhóm</h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenCreate}
              >
                Thêm nhóm
        </Button>
            </GridItem>
            <Table
              tableHead={[
                "Tên nhóm",
                "Tên người quản lý",
                "Số lượng thành viên",
                "Hành động"
              ]}
              tableData={[...dataList]}
            />
          </CardBody>
        </Card>
      </GridItem>}
      {role == "Admin" && <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bảng danh sách thành viên trong nhóm</h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
            </GridItem>
            <Table
              tableHead={[
                "Tên đang nhập",
                "Email",
                "Nhà mạng",
                "Danh mục",
                "Trạng thái",
                "Hành động"
              ]}
              tableData={[...dataMyTeam]}
              customCellClasses={[classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200]}
              customClassesForCells={[1, 2, 3, 4, 5]}
              customHeadCellClasses={[classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200]}
              customHeadClassesForCells={[1, 2, 3, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>}
      {role == "Admin" && <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bảng danh sách thành viên chưa có nhóm</h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
            </GridItem>
            <Table
              tableHead={[
                "Tên đang nhập",
                "Email",
                "Nhà mạng",
                "Danh mục",
                "Trạng thái",
                "Hành động"
              ]}
              tableData={[...dataOutTeam]}
              customCellClasses={[classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200]}
              customClassesForCells={[1, 2, 3, 4, 5]}
              customHeadCellClasses={[classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200, classes.maxWidth200]}
              customHeadClassesForCells={[1, 2, 3, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>}
      <DialogCRUDTeam
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        textDialog={textDialog}
        classes={classes}
        setOpenLoading={setOpenLoading}
        setSnackbar={setSnackbar}
        snackbarData={snackbarData}
        getListData={getListData}
        dataDialogCurrent={dataDialog}
      >

      </DialogCRUDTeam>
      <DialogConfirm
        open={openDialogConfirm}
        handleClose={handleCloseDialogConfirm}
        handOK={handleDelete}
        msg="Bạn có chắc chắn muốn xóa"
        classes={classes}
      ></DialogConfirm>

      <EditUser
        setOpenCreate={setOpenCreate}
        openCreate={openCreate}
        idEdit={idEdit}
        classes={classes}
        setOpenLoading={setOpenLoading}
        getDataMyTeam={getDataMyTeam}
      >

      </EditUser>
      <Snackbar
        open={snackbarData.open}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbar(snackbarDefault)
        }}
      >
        <MuiAlert severity={snackbarData.status}>{snackbarData.msg}</MuiAlert>
      </Snackbar>
    </GridContainer>
  );
}


const DialogCRUDTeam = (props) => {
  const { openDialog, handleCloseDialog, textDialog,
    classes, setOpenLoading, setSnackbar, snackbarData, getListData, dataDialogCurrent } = props;
  const [listAdmin, setListAdmin] = useState([])
  const [listUser, setListUser] = useState([])
  const [dataDialog, setDataDialog] = useState({
    Name: '',
    TeamLeadId: '',
    Address: '',
    Id: ''
  });

  useEffect(() => {
    getDataListIUsers();
    if (openDialog) {
      setDataDialog({
        Id: dataDialogCurrent.teamId,
        Name: dataDialogCurrent.name,
        TeamLeadId: dataDialogCurrent.teamLead,
        Address: dataDialogCurrent.address,
      })
    }

  }, [openDialog])

  const getDataListIUsers = async () => {
    try {
      const result = await fetchClient.get("/users/list-user-role/?roleName=Admin");
      if (result) {
        if (result.data) {
          var data = result.data;
          var listAdminResult = [];
          data.forEach(e => {
            listAdminResult.push({
              userId: e.userId,
              userName: e.userName,
            })
          });
          if (dataDialogCurrent.teamLead) {
            listAdminResult.push({
              userId: dataDialogCurrent.teamLead,
              userName: dataDialogCurrent.teamLeadName,
            })
          }

          setListAdmin(listAdminResult);
        }
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [errorText, setErrorText] = useState({});
  const [errorFile, setErrorFile] = useState({});


  const CRUDTeam = async () => {
    const formData = new FormData();
    if (dataDialog.Id) {
      formData.append("Id", dataDialog.Id);
      formData.append("TeamLeadIdOld", dataDialogCurrent.teamLead);
    }
    formData.append("Name", dataDialog.Name);
    formData.append("TeamLeadId", dataDialog.TeamLeadId);
    formData.append("Address", dataDialog.Address);

    return await fetchClient.post("/team/crud-team/", formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  const handleSubmit = async () => {
    if (validate()) {
      setOpenLoading(true);
      try {
        CRUDTeam().then((result) => {
          if (result) {
            setOpenLoading(false);
            handleCloseDialog();
            setSnackbar({
              ...snackbarData,
              open: true,
              status: 'success',
              msg: 'Thành công'
            })
            getListData();
            return result;
          }
        })
      } catch (error) {
        setOpenLoading(false);
        setSnackbar({
          ...snackbarData,
          open: true,
          status: 'error',
          msg: 'Thất bại'
        })
        console.log(error);
      }
    }
  };
  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;
    const {
      Name,
      TeamLeadId,
      Address,
    } = dataDialog;

    if (Name === "") {
      numberErr++;
      error.Name = true;
      helperText.Name = "Đây là trường bắt buộc";
    }

    if (TeamLeadId === "") {
      numberErr++;
      error.TeamLeadId = true;
      helperText.TeamLeadId = "Đây là trường bắt buộc";
    }
    if (Address === "") {
      numberErr++;
      error.Address = true;
      helperText.Address = "Đây là trường bắt buộc";
    }

    setErrorText(helperText);
    setErrorFile(error);

    return !numberErr;
  };

  const onchangeInput = (fieldName, value) => {
    setDataDialog({ ...dataDialog, [fieldName]: value });
  };

  return (<Dialog
    open={openDialog}
    fullWidth
    maxWidth="md"
    onClose={handleCloseDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      {textDialog} nhóm
  </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Tên nhóm"
              inputProps={{
                value: dataDialog.Name,
                onChange: (e) =>
                  onchangeInput("Name", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.Name ? errorText.Name : ""}
              error={errorFile.Name}
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={8}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="Role" className={classes.selectLabel}>
                TeamLead
              </InputLabel>
              <Select
                value={dataDialog.TeamLeadId}
                onChange={(e) => onchangeInput("TeamLeadId", e.target.value)}
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                error={errorFile.TeamLeadId}
              >
                {listAdmin.length > 0 &&
                  listAdmin.map((e) =>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={e.userId}
                      key={e.userId}
                    >
                      {e.userName}
                    </MenuItem>
                  )

                }
              </Select>
              <FormHelperText error>
                {errorText.TeamLeadId ? errorText.TeamLeadId : ""}
              </FormHelperText>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Khu vực"
              inputProps={{
                value: dataDialog.Address,
                onChange: (e) =>
                  onchangeInput("Address", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.Address ? errorText.Address : ""}
              error={errorFile.Address}
            />
          </GridItem>
        </GridContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleCloseDialog} color="danger">
        Hủy bỏ
      </Button>
      <Button onClick={handleSubmit} color="primary" autoFocus>
        {textDialog}
      </Button>
    </DialogActions>
  </Dialog>)

}

const DialogConfirm = (props) => {
  const { open, handleClose, handOK, msg, classes } = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title"></DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.textCenter}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.maxWidth200}>
        <Button autoFocus onClick={handleClose} color="primary">
          Đóng
        </Button>
        <Button autoFocus onClick={handOK} color="danger">
          Xóa
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditUser = (props) => {

  const { setOpenCreate, openCreate, idEdit, classes, setOpenLoading, getDataMyTeam } = props
  const [data, setData] = useState({
    Id: "",
    UserName: "",
    Role: "",
    FirstName: "",
    LastName: "",
    IsActive: "",
    MobileNetworkSupplier: [],
    DataCategory: [],
  });

  const [listCategory, setCategoryList] = useState([]);
  const [errorText, setErrorText] = useState({});
  const [errorFile, setErrorFile] = useState({});

  useEffect(() => {
    if (openCreate) {
      handleCategoryList();
      getDataCurrent();
    }
  }, [openCreate])

  const getDataCurrent = () => {
    getUserById().then((result) => {
      var value = result.data;
      setData({
        Id: value.id,
        UserName: value.userName,
        FirstName: value.firstName,
        LastName: value.lastName,
        IsActive: value.isActive,
        MobileNetworkSupplier: value.mobileNetworkSupplier?.split(",") || [],
        DataCategory: value.dataCategory?.split(",") || [],
      })
    })
  }

  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;
    const {
      FirstName,
      LastName,
      UserName,
      IsActive,
      DataCategory,
      MobileNetworkSupplier,
    } = data;

    if (!FirstName) {
      numberErr++;
      error.FirstName = true;
      helperText.FirstName = "Đây là trường bắt buộc";
    }

    if (!LastName) {
      numberErr++;
      error.LastName = true;
      helperText.LastName = "Đây là trường bắt buộc";
    }

    if (!UserName) {
      numberErr++;
      error.UserName = true;
      helperText.UserName = "Đây là trường bắt buộc";
    }
    if (UserName && UserName.length < 6) {
      numberErr++;
      error.UserName = true;
      helperText.UserName = "Độ dài tối thiểu là 6";
    }

    if (IsActive.toString() === "") {
      numberErr++;
      error.IsActive = true;
      helperText.IsActive = "Đây là trường bắt buộc";
    }
    if (!DataCategory.length) {
      numberErr++;
      error.DataCategory = true;
      helperText.DataCategory = "Đây là trường bắt buộc";
    }
    if (!MobileNetworkSupplier.length) {
      numberErr++;
      error.MobileNetworkSupplier = true;
      helperText.MobileNetworkSupplier = "Đây là trường bắt buộc";
    }
    setErrorText(helperText);
    setErrorFile(error);

    return !numberErr;
  };


  const getDataCategory = async () => {
    try {
      const result = await fetchClient.get("/category/categoryActive/");
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCategoryList = () => {
    getDataCategory().then((data) => {
      if (data) {
        var dataResult = data.data || [];
        var listCategory = [];
        dataResult.map((data) => {
          listCategory.push({
            key: data.id.toString(),
            value: data.categoryName,
          });
        });
        setCategoryList(listCategory);
      }
    });
  };


  const onchangeInput = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };


  const callApiUpdate = async () => {
    const formData = new FormData();
    if (validate()) {
      setOpenLoading(true);
      formData.append("Id", data.Id);
      formData.append("FirstName", data.FirstName);
      formData.append("LastName", data.LastName);
      formData.append("UserName", data.UserName);
      formData.append("IsActive", data.IsActive);
      formData.append("DataCategory", data.DataCategory);
      formData.append(
        "MobileNetworkSupplier",
        data.MobileNetworkSupplier
      );
      try {
        const result = await fetchClient.post(
          "/identity/edit-user",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result) {
          setOpenLoading(false);
          handleCloseDialog();
          return result;
        }
      } catch (error) {
        setOpenLoading(false);
        console.log(error);
      }
    }
  }

  const handleSubmit = () => {
    callApiUpdate().then((result) => {
      if (result) {
        getDataMyTeam();
        handleCloseDialog();
      }
    })

  }
  const handleCloseDialog = () => {
    setData({
      UserName: "",
      Role: "",
      FirstName: "",
      LastName: "",
      IsActive: "",
      MobileNetworkSupplier: [],
      DataCategory: [],
    })
    setOpenCreate(false)
  }

  const getUserById = async () => {
    try {
      const result = await fetchClient.get(`/users/iUserById/?Id=${idEdit}`);
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (<Dialog
    open={openCreate}
    fullWidth
    maxWidth="md"
    onClose={handleCloseDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      {" Chỉnh sửa tài khoản"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <GridContainer justify="center">
          <GridItem xs={12} sm={5} md={5}>
            <CustomInput
              labelText="Tài khoản"
              inputProps={{
                value: data.UserName,
                onChange: (e) =>
                  onchangeInput("UserName", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.UserName ? errorText.UserName : ""}
              error={errorFile.UserName}
            />
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="status"
                className={classes.selectLabel}
              >
                Trạng Thái
              </InputLabel>
              <Select
                value={data.IsActive}
                onChange={(e) =>
                  onchangeInput("IsActive", e.target.value)
                }
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                error={errorFile.IsActive}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Trạng Thái
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={false}
                >
                  Không hoạt động
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={true}
                >
                  Hoạt động
                </MenuItem>
              </Select>
              <FormHelperText>
                {errorText.IsActive ? errorText.IsActive : ""}
              </FormHelperText>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <CustomInput
              labelText="Họ"
              inputProps={{
                value: data.FirstName,
                onChange: (e) =>
                  onchangeInput("FirstName", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.FirstName ? errorText.FirstName : ""}
              error={errorFile.FirstName}
            />
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <CustomInput
              labelText="Tên"
              inputProps={{
                value: data.LastName,
                onChange: (e) =>
                  onchangeInput("LastName", e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.LastName ? errorText.LastName : ""}
              error={errorFile.LastName}
            />
          </GridItem>

          <GridItem xs={12} sm={10} md={10}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="MobileNetworkSupplier"
                className={classes.selectLabel}
              >
                Giới hạn mạng điện thoại
              </InputLabel>
              <Select
                multiple
                value={data.MobileNetworkSupplier}
                onChange={(e) =>
                  onchangeInput("MobileNetworkSupplier", e.target.value)
                }
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                error={errorFile.MobileNetworkSupplier}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Giới hạn mạng điện thoại
                </MenuItem>
                {MobileNetworkSupplierList &&
                  MobileNetworkSupplierList.map((e, i) => {
                    return (
                      <MenuItem
                        key={i + i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected:
                            classes.selectMenuItemSelectedMultiple,
                        }}
                        value={e.value}
                      >
                        {e.value}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText error>
                {errorText.MobileNetworkSupplier ? errorText.MobileNetworkSupplier : ""}
              </FormHelperText>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={10} md={10}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="DataCategory"
                className={classes.selectLabel}
              >
                Giới hạn dữ liệu
              </InputLabel>
              <Select
                multiple
                value={data.DataCategory}
                onChange={(e) =>
                  onchangeInput("DataCategory", e.target.value)
                }
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                error={errorFile.DataCategory}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Giới hạn dữ liệu
                </MenuItem>
                {listCategory &&
                  listCategory.map((e, i) => {
                    return (
                      <MenuItem
                        key={i + i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected:
                            classes.selectMenuItemSelectedMultiple,
                        }}
                        value={e.key}
                      >
                        {e.value}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText error>
                {errorText.DataCategory ? errorText.DataCategory : ""}
              </FormHelperText>
            </FormControl>
          </GridItem>
        </GridContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleCloseDialog} color="danger">
        Hủy bỏ
      </Button>
      <Button onClick={handleSubmit} color="primary" autoFocus>
        Cập nhập
      </Button>
    </DialogActions>
  </Dialog>)
}