import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// material-ui icons
import { CloudUploadTwoTone, History } from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import { DropzoneArea } from "material-ui-dropzone";
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { FormControl, Snackbar, NativeSelect } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MuiAlert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteForever from "@material-ui/icons/DeleteForever";
import FormHelperText from "@material-ui/core/FormHelperText";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import * as XLSX from "xlsx";

import fetchClient from "../../ultils/request";
import { getSession } from "../../ultils/appSession";

const stylesForm = {
  ...styles,
  ...styles2,
  marginTop20: {
    marginTop: "20px",
  },
  justifyContentGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
  errorText: {
    color: "red",
  },
  center: {
    justifyContent: "center",
  },
  textCenter: {
    textAlign: "center",
  },
};

const useStyles = makeStyles(stylesForm);
export default function ImportPage() {
  const classes = useStyles();
  const [files, setFiles] = useState();
  const [dataType, setDataType] = useState();
  const [message, setMessage] = useState();
  const [openMess, setOpen] = useState(false);
  const [openMessSuccess, setOpenSuccess] = useState(false);
  const [listCategory, setCategoryList] = useState([]);
  const [dataPreview, setDataPreview] = useState([]);
  const [openLoading, setOpenLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataListFile, setDataListFile] = useState([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [errorText, setErrorText] = useState({});
  const [errorFile, setErrorFile] = useState({});
  const currentSession = getSession();
  const roleAdminBase = currentSession.userRoles == "Admin" ? true : false;
  const submitData = async () => {
    try {
      const formData = new FormData();
      formData.append("files", files);
      formData.append("typeData", dataType);
      const result = await fetchClient.post("/importdatas", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitData = () => {
    if (validateInput()) {
      setOpenLoading(true);
      submitData().then((data) => {
        if (data) {
          setOpenSuccess(true);
          setOpenLoading(false);
          setMessage("Tải thành công");
          handleDataList();
        } else {
          setOpen(true);
          setOpenLoading(false);
          setMessage("Tải thất bại");
        }
      });
    }
  };

  const getDataCategory = async () => {
    try {
      const result = await fetchClient.get("/category/categoryActive/");
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCategoryList = () => {
    getDataCategory().then((data) => {
      if (data) {
        var dataResult = data.data || [];
        var listCategory = [];
        dataResult.map((data) => {
          listCategory.push({
            key: data.id,
            value: data.categoryName,
          });
        });
        setCategoryList(listCategory);
      }
    });
  };

  const getDataListFile = async () => {
    setOpenLoading(true);
    try {
      const result = await fetchClient.get("/importdatas");
      if (result) {
        setOpenLoading(false);
        return result;
      }
    } catch (e) {
      setOpenLoading(false);
      console.log(e);
    }
  };
  const onclickDelete = (id) => {
    setOpenDialogConfirm(true);
    setIdDelete(id);
  };

  const handleDataList = () => {
    getDataListFile().then((result) => {
      if (result && result.data) {
        const dataTable = [];
        console.log(result.data);
        result.data.forEach((e) => {
          dataTable.push([
            e.fileName,
            e.dataCategory,
            moment(e.createdOn).format("MM-DD-YYYY HH:mm:ss"),
            e.duplicationRecord,
            e.totalRecordInstalled,
            <Button
              round
              color='danger'
              className={classes.actionButton + " " + classes.actionButtonRound}
              onClick={() => onclickDelete(e.id)}
            >
              <DeleteForever className={classes.icon} />
            </Button>
          ]);
        });
        setDataListFile(dataTable);
      }
    });
  };

  useEffect(() => {
    handleCategoryList();
    handleDataList();
  }, []);

  const validateInput = () => {
    var error = {};
    var helperText = {};
    let err = 0;
    if (!files || files.length < 1 || files == "undefined") {
      err++;
      error.files = true;
      helperText.files = "Đây là trường bắt buộc";
    }
    if (!dataType || dataType == "undefined" || dataType == "") {
      err++;
      error.dataType = true;
      helperText.dataType = "Đây là trường bắt buộc";
    }
    setErrorText(helperText);
    setErrorFile(error);
    return !err;
  };

  const simpleButtons = [{ color: "danger", icon: Close }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const getDataPreview = () => {
    const mineTypeExcel = [
      "application/xhtml+xml",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    try {
      if (files) {
        const reader = new FileReader();
        if (mineTypeExcel.includes(files.type)) {
          reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const dataTable = data.slice(1, 11);
            setDataPreview(dataTable);
          };
          reader.readAsBinaryString(files);
        } else {
          reader.onload = async (e) => {
            const allText = e.target.result;
            var allTextLines = allText.split(/\r\n|\n/);
            var data = [];
            for (var i = 0; i < allTextLines.length; i++) {
              data.push(allTextLines[i].split(","));
            }
            const dataTable = data.slice(1, 11);
            setDataPreview(dataTable);
          };
          reader.readAsText(files);
        }

        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const preview = () => {
    var result = getDataPreview();
    setOpenDialog(result);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDataPreview([]);
  };

  const handleDelete = async () => {
    try {
      setOpenLoading(true);
      const result = await fetchClient.delete(`/importdatas/?id=${idDelete}`);
      if (result.status == 200) {
        setOpenSuccess(true);
        setOpenLoading(false);
        setMessage("Xóa thành công");
        handleCloseDialogConfirm();
        handleDataList();
      } else {
        setOpen(true);
        setOpenLoading(false);
        setMessage("Xóa thất bại");
      }
    } catch (e) {
      setOpenLoading(false);
      console.log(e);
    }
  };
  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  return (
    <>
      {roleAdminBase ? "Tài khoản của bạn không có quyền xem trang này !" :
        <div>
          <Backdrop className={classes.backdrop} open={openLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <GridContainer>
            <GridItem xs={12}>
              <DropzoneArea
                onChange={(files) => setFiles(files[0])}
                showPreviews={false}
                maxFileSize={209715200}
                filesLimit={1}
                dropzoneText="Kéo, thả file hoặc click vào đây để tải file lên."
                clearOnUnmount
                fullWidth
                acceptedFiles={[
                  "application/xhtml+xml",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ".csv",
                ]}
              />
              <FormHelperText className={classes.errorText}>
                {errorText.files ? errorText.files : ""}
              </FormHelperText>
              {files && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={preview}
                  fullWidth
                >
                  Xem trước 10 bản ghi
                </Button>
              )}
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              mt={10}
              className={classes.marginTop20}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className={classes.justifyContentGrid}
                >
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="dataType" className={classes.selectLabel}>
                      Loại dữ liệu
                </InputLabel>
                    <Select
                      value={dataType}
                      onChange={(e) => setDataType(e.target.value)}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                    >
                      {listCategory &&
                        listCategory.map((e, i) => {
                          return (
                            <MenuItem
                              key={i + i}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple,
                              }}
                              value={e.key}
                            >
                              {e.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText className={classes.errorText}>
                      {errorText.dataType ? errorText.dataType : ""}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={4}
                  md={4}
                  className={classes.justifyContentGrid}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitData}
                  >
                    Upload
              </Button>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Bảng danh sách file tải lên
                </h4>
                  </CardHeader>
                  <CardBody>
                    <Table
                      tableHead={[
                        "Tên",
                        "Loại dữ liệu",
                        "Ngày tải",
                        "Số bản ghi bị trùng",
                        "Tổng số bản ghi đã cài đặt",
                        "Hành động",
                      ]}
                      tableData={[...dataListFile]}
                    />
                  </CardBody>
                </Card>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <Snackbar
            open={openMess}
            autoHideDuration={6000}
            onClose={() => {
              setOpen(false);
              setMessage("");
            }}
          >
            <MuiAlert severity="error">{message}</MuiAlert>
          </Snackbar>
          <Snackbar
            open={openMessSuccess}
            autoHideDuration={6000}
            onClose={() => {
              setOpenSuccess(false);
              setMessage("");
            }}
          >
            <MuiAlert severity="success">{message}</MuiAlert>
          </Snackbar>

          <PreviewDialog
            dataPreview={dataPreview}
            classes={classes}
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
          ></PreviewDialog>
          <DialogConfirm
            open={openDialogConfirm}
            handleClose={handleCloseDialogConfirm}
            handOK={handleDelete}
            msg="Bạn có chắc chắn muốn xóa"
            classes={classes}
          ></DialogConfirm>
        </div>}
    </>
  );
}

const PreviewDialog = (props) => {
  const { openDialog, handleCloseDialog, dataPreview, classes } = props;
  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialog}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Xem trước file tải lên
      </DialogTitle>
      <DialogContent>
        <GridContainer justify="center">
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Bảng dữ liệu</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "Người nhận",
                  "Điện thoại Người nhận",
                  "Địa Chỉ Người Nhận",
                  "Nội Dung Hàng Hóa",
                  "COD",
                ]}
                tableData={[...dataPreview]}
              />
            </CardBody>
          </Card>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialog} color="danger">
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const DialogConfirm = (props) => {
  const { open, handleClose, handOK, msg, classes } = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title"></DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.textCenter}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.center}>
        <Button autoFocus onClick={handleClose} color="primary">
          Đóng
        </Button>
        <Button autoFocus onClick={handOK} color="danger">
          Xóa
        </Button>
      </DialogActions>
    </Dialog>
  );
};
