import moment from "moment";

export const checkAuth = () => {
  let isTokenExpired = true;
  const expiresOn = localStorage.getItem("expiresOn");
  if (expiresOn) {
    isTokenExpired = moment(expiresOn) < moment();
  }

  return (
    localStorage.getItem("jwToken") &&
    localStorage.getItem("userName") &&
    localStorage.getItem("userNameId") &&
    localStorage.getItem("userRoles") &&
    !isTokenExpired
  );
};

export const setSession = ({
  jwToken,
  userName,
  userNameId,
  roles,
  issuedOn,
  expiresOn,
}) => {
  localStorage.setItem("jwToken", jwToken);
  localStorage.setItem("userName", userName);
  localStorage.setItem("userNameId", userNameId);
  localStorage.setItem("userRoles", roles);
  localStorage.setItem("issuedOn", issuedOn);
  localStorage.setItem("expiresOn", expiresOn);
};

export const getSession = () => {
  return {
    token: localStorage.getItem("jwToken"),
    userName: localStorage.getItem("userName"),
    userNameId: localStorage.getItem("userNameId"),
    userRoles: localStorage.getItem("userRoles"),
  };
};

export const removeSession = () => {
  localStorage.setItem("jwToken", "");
  localStorage.setItem("userName", "");
  localStorage.setItem("userNameId", "");
  localStorage.setItem("userRoles", "");
  localStorage.setItem("issuedOn", "");
  localStorage.setItem("expiresOn", "");
};

export const isAdminstrator = () => {
  // Basic,Moderator,SuperAdmin,Admin
  const roles = localStorage.getItem("userRoles");
  return roles.includes("Admin");
};
