import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import fetchClient from "../../ultils/request";

export default function DataCategory({ onSelected }) {
  useEffect(() => {
    if (categoryData.length == 0) {
      setIsLoading(true);
      fetchClient
        .get("/category")
        .then((respone) => setCategoryData(respone.data))
        .catch((err) => console.error(err))
        .finally(setIsLoading(false));
    }
  });

  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
    onSelected(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        Chọn danh mục dữ liệu
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="data-category-select"
        onChange={handleChange}
        disabled={isLoading}
        multiple
        value={selectedCategory}
      >
        {categoryData.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.categoryName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
