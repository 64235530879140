export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;
  if (phoneNumber.length < 10) return phoneNumber;
  return `${phoneNumber.slice(0, 3)}.${phoneNumber.slice(
    3,
    6
  )}.${phoneNumber.slice(6, 10)}`;
};

export const formatCurrency = (amount) => {
  if (!amount) return amount;

  return Number(amount).toLocaleString("it-IT", {
    style: "currency",
    currency: "vnd",
  });
};
