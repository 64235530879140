import React, { useState, useEffect } from "react";
import moment from "moment";
import { formatCurrency, formatPhoneNumber } from "../../../ultils/formater";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import Search from "@material-ui/icons/Search";

import CustomInput from "components/CustomInput/CustomInput";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import queryString from 'query-string';
import fetchClient from "../../../ultils/request";


const stylesForm = {
  ...styles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
  button: {
    alignSelf: 'center',
  },
  name: {
    fontSize: '20px',
    fontWeight: 700,
    color: 'darkmagenta',
  },
  phoneNumber: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#f10000',
  },
  cod: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#56af39',
  },
  productBuy: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#56af39',
  },
  logStatusCode: {
    fontSize: '16px',
    fontWeight: 700,
  },
  cityName: {
    fontSize: '16px',
    fontWeight: 700,
  },
  time: {
    color: '#0347c3',
    fontSize: '12px',
    fontWeight: 400,
  },
  product: {
    color: '#020202',
    fontSize: '14px',
    fontWeight: 400,
  },
  address: {
    color: '#0347c3',
    fontSize: '14px',
    fontWeight: 400,
  },
  color1: {
    color: '#4caf50',
  },
  color2: {
    color: '#f44336',
  },
  color3: {
    color: '#e91e63',
  },
  color4: {
    color: '#ff9800',
  },
  color5: {
    color: '#999',
  },
  end: {
    textAlign: 'end'
  },
  contents: {
    maxHeight: '72vh',
    overflowY: 'auto',
  }
};

var statusCustomer = [
  {
    key: 1,
    value: "Đồng ý",
  },
  {
    key: 2,
    value: "Từ chối",
  },
  {
    key: 3,
    value: "Không bắt máy",
  },
  {
    key: 4,
    value: "Số điện thoại sai",
  },
  {
    key: 5,
    value: "Bỏ qua",
  }
];

const useStyles = makeStyles(stylesForm);
const snackbarDefault = {
  open: false,
  msg: '',
  status: 'success'
}

export default function UserCallHistoryPage() {
  const classes = useStyles();
  const [usersTextSearch, setUsersTextSearch] = useState("");
  const [data, setData] = useState([]);
  const [openLoading, setOpenLoading] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [dataDialogEdit, setDataDialogEdit] = useState({});
  const [snackbarData, setSnackbar] = useState(snackbarDefault);


  const handleSearchData = async () => {
    try {
      const result = await fetchClient(`/v1/UserCallLogs/my-customer/?PhoneNumber=${usersTextSearch}`);
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [])

  const handleSearch = () => {
    setOpenLoading(true);
    handleSearchData().then((data) => {
      var dataTable = [];
      if (data) {
        dataTable = data.data;
        // var dataMapTable = [];
        // dataTable.forEach((e) => {
        //   dataMapTable.push([
        //     e.fullName,
        //     e.phoneNumber,
        //     e.productBuy,
        //     statusCustomer.filter(x => x.key == e.logStatusCode)[0]?.value,
        //     e.createdOn ? moment(e.createdOn).format("MM-DD-YYYY HH:mm:ss") : "---",
        //     <Button color="success" className={classes.actionButton + " " + classes.actionButtonRound}
        //       onClick={() => handleOpenDialogEdit(e)}>
        //       <Edit color="success" />
        //     </Button>
        //   ]);
        // });
        // setData(dataMapTable);
        setData(dataTable);
      }
      setOpenLoading(false);
    });
  };

  const handleSubmitEdit = async (customerId, userCallLogId, statusCode, productBuy, address, cod) => {
    var query = queryString.stringify({
      customerId: customerId,
      userCallLogId: userCallLogId,
      statusCode: statusCode,
      productBuy: productBuy,
      address: address,
      cod: cod,
    }, { arrayFormat: 'comma' });
    return await fetchClient.post(`/v1/UserCallLogs/edit-my-customer/?${query}`);
  };


  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    setDataDialogEdit({})
  }

  const handleOpenDialogEdit = (value) => {
    setOpenDialogEdit(true)
    setDataDialogEdit(value)
  }
  const setClassNameStatus = (status) => {

    var className = ""
    switch (status) {
      case 1:
        className = classes.color1
        break;
      case 2:
        className = classes.color2
        break;
      case 3:
        className = classes.color3
        break;
      case 4:
        className = classes.color4
        break;
      case 5:
        className = classes.color5
        break;

      default:
        break;
    }
    return className
  }

  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardHeader color="rose" icon>
          <h4 className={classes.cardIconTitle}>Lịch sử cuộc gọi</h4>
        </CardHeader>
        <CardBody>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={8} sm={8} md={8}>
                <CustomInput
                  labelText="Số điện thoại"
                  inputProps={{
                    defaultValue: usersTextSearch,
                    onChange: (e) =>
                      setUsersTextSearch(e.target.value),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4} className={classes.button}>
                <Button color="primary" onClick={handleSearch}>
                  Tìm kiếm
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>

          {/* <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Danh sách dữ liệu</h4>
              </CardHeader>
              <CardBody> */}
          {/* <Table
                  tableHead={[
                    "Họ Tên",
                    "SĐT",
                    "Vùng miền",
                    "Trạng thái",
                    "Thời gian gọi",
                    "Hành động"
                  ]}
                  tableData={[...data]}
                /> */}
          <div className={classes.contents}>
            {data.length > 0 && data.map((e) =>
              <GridItem xs={12} key={e.customerId}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={6}>
                        <p className={classes.name}>{e.fullName}</p>
                        <p className={classes.productBuy}>{e.productBuy}</p>
                      </GridItem>
                      <GridItem xs={6} className={classes.end}>
                        <span className={classes.logStatusCode + " " + setClassNameStatus(e.logStatusCode)}
                        >{statusCustomer.filter(x => x.key == e.logStatusCode)[0]?.value}</span>
                        <br />
                        <span className={classes.time} >{e.createdOn ? moment(e.createdOn).format("MM-DD-YYYY HH:mm:ss") : "---"}</span>
                      </GridItem>
                      <GridItem xs={12}>
                        <p className={classes.phoneNumber}>{formatPhoneNumber(e.phoneNumber)}</p>
                      </GridItem>
                      <GridItem xs={12}>
                        <p className={classes.cod}>{formatCurrency(e.cod)}</p>
                      </GridItem>
                      <GridItem xs={10}>
                        <p className={classes.product}>{e.product}</p>
                      </GridItem>
                      <GridItem xs={10}>
                        <p className={classes.address}>{e.address}</p>
                      </GridItem>
                      <GridItem xs={2}>
                        <Button color="success" className={classes.actionButton + " " + classes.actionButtonRound}
                          onClick={() => handleOpenDialogEdit(e)}
                        >
                          <Edit />
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                {/* </CardBody>
            </Card> */}
              </GridItem>
            )}
          </div>
        </CardBody>
      </Card>

      <UpdateUser
        openDialog={openDialogEdit}
        handleCloseDialog={handleCloseDialogEdit}
        dataDialog={dataDialogEdit}
        handleSubmit={handleSubmitEdit}
        handleSearch={handleSearch}
        classes={classes}
        setSnackbar={setSnackbar}
        snackbarData={snackbarData}
        setOpenLoading={setOpenLoading}
      ></UpdateUser>
      <Snackbar
        open={snackbarData.open}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbar(snackbarDefault)
        }}
      >
        <MuiAlert severity={snackbarData.status}>{snackbarData.msg}</MuiAlert>
      </Snackbar>

    </GridContainer>
  );
}
const UpdateUser = (props) => {
  const { openDialog, handleCloseDialog, dataDialog, handleSubmit,
    handleSearch, classes, setSnackbar, snackbarData, setOpenLoading } = props
  const [status, setStatus] = useState(0);
  const [productBuy, setProductBuy] = useState("Body mist C28");
  const [address, setAddress] = useState("Miền Bắc");
  const [cod, setCod] = useState(0);


  useEffect(() => {
    if (dataDialog) {
      setStatus(dataDialog.logStatusCode)
      setAddress(dataDialog.address)
      setCod(dataDialog.cod)
      if (dataDialog.productBuy) {
        setProductBuy(dataDialog.productBuy)
      }
    }
  }, [openDialog])

  const closeDialog = () => {
    setStatus();
    handleCloseDialog();
  }

  const submitEdit = () => {
    try {
      setOpenLoading(true);
      handleSubmit(dataDialog.customerId, dataDialog.userCallLogId, status, productBuy, address, cod).then((result) => {
        if (result && result.data) {
          setOpenLoading(false);
          handleSearch();
          closeDialog();
          setSnackbar({
            ...snackbarData,
            open: true,
            status: 'success',
            msg: 'Thành công'
          })
        }
      })
    } catch (error) {
      setOpenLoading(false);
      setSnackbar({
        ...snackbarData,
        open: true,
        status: 'error',
        msg: 'Thất bại'
      })
      console.log(error);
    }

  }

  return (<Dialog
    open={openDialog}
    onClose={handleCloseDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      Sửa trạng thái cho <strong style={{ color: 'blue' }}>{dataDialog.fullName}</strong>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="status"
                className={classes.selectLabel}
              >
                Trạng Thái
              </InputLabel>
              <Select
                value={status}
                onChange={(e) =>
                  setStatus(e.target.value)
                }
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Trạng Thái
                </MenuItem>
                {statusCustomer &&
                  statusCustomer.map((e, i) => {
                    return (
                      <MenuItem
                        key={i + i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected:
                            classes.selectMenuItemSelectedMultiple,
                        }}
                        value={e.key}
                      >
                        {e.value}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </GridItem>
          {status == 1 && <GridItem xs={12} sm={8} md={8}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="productBuy"
                className={classes.selectLabel}
              >
                Sản phẩm
              </InputLabel>
              <Select
                value={productBuy}
                onChange={(e) =>
                  setProductBuy(e.target.value)
                }
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected:
                      classes.selectMenuItemSelectedMultiple,
                  }}
                  value={"Body mist C28"}
                >
                  Body mist C28
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected:
                      classes.selectMenuItemSelectedMultiple,
                  }}
                  value={"Body mist C34"}
                >
                  Body mist C34
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected:
                      classes.selectMenuItemSelectedMultiple,
                  }}
                  value={"Bleu Chanel 100ml"}
                >
                  Bleu Chanel 100ml
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected:
                      classes.selectMenuItemSelectedMultiple,
                  }}
                  value={"CoCo chanel 100ml"}
                >
                  CoCo chanel 100ml
                </MenuItem>

              </Select>
            </FormControl>
          </GridItem>}
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Cod"
              inputProps={{
                value: cod,
                // multiline: true,
                onChange: (e) =>
                  setCod(e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Địa chỉ"
              inputProps={{
                value: address,
                multiline: true,
                onChange: (e) =>
                  setAddress(e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        </GridContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={closeDialog} color="danger">
        Hủy bỏ
      </Button>
      <Button onClick={submitEdit} color="primary" autoFocus>
        Lưu
      </Button>
    </DialogActions>
  </Dialog>)
}