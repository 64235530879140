import React, { useState, useEffect } from "react";
import queryString from 'query-string';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import Search from "@material-ui/icons/Search";
// Dialog
import {
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { TransferWithinAStation, PersonAdd, VisibilityOff, Visibility, Settings, Edit, VpnKey } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DataGrid, GridToolbarContainer } from "@material-ui/data-grid";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import _ from "lodash";
import Transfer from "../Components/Transfer/index.js";

import fetchClient from "../../ultils/request";
import CustomInput from "components/CustomInput/CustomInput";
import { getSession } from "../../ultils/appSession";

const stylesForm = {
  ...styles,
  ...styles2,
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
  cardbody: {
    height: 550,
  },
};

const MobileNetworkSupplierList = [
  {
    key: 1,
    value: "VIETTEL",
  },
  {
    key: 2,
    value: "VINAPHONE",
  },
  {
    key: 3,
    value: "MOBIPHONE",
  },
  {
    key: 4,
    value: "VIETNAMOBILE",
  },
  {
    key: 5,
    value: "ITELECOM",
  },
  {
    key: 6,
    value: "NHÀ MẠNG KHÁC",
  },
];
const statusCustomer = [
  {
    key: 0,
    value: "Chưa gọi",
  },
  {
    key: 1,
    value: "Đồng ý",
  },
  {
    key: 2,
    value: "Từ chối",
  },
  {
    key: 3,
    value: "Không bắt máy",
  },
  {
    key: 4,
    value: "Số điện thoại sai",
  },
  {
    key: 5,
    value: "Bỏ qua",
  },
  {
    key: 6,
    value: "Khóa",
  },
];
const useStyles = makeStyles(stylesForm);
export default function UserPage() {
  const classes = useStyles();
  const currentSession = getSession();
  const role = currentSession.userRoles == "Admin" ? "User" : "Admin";
  const [listStatus, setDataListStatus] = useState([]);
  const [listCategory, setCategoryList] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [errorText, setErrorText] = useState({});
  const [errorFile, setErrorFile] = useState({});
  const [userList, setUserList] = useState([]);
  const [showDeletedUser, setShowDeletedUser] = useState(false);
  const [userCreate, setUserCreate] = useState({
    UserName: "",
    Password: "",
    FirstName: "",
    LastName: "",
    Role: role,
    IsActive: "",
    Email: "",
    MobileNetworkSupplier: [],
    DataCategory: [],
  });
  const [openLoading, setOpenLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [openBlogDialog, setOpenBlogDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);



  useEffect(() => {
    setDataListStatus(statusCustomer);
    handleCategoryList();
    if (userList.length === 0) {
      handleGetUserList();
    }
  }, []);

  const customToolbar = () => (
    <GridToolbarContainer>
      <Button
        color="primary"
        simple
        disabled={selectedUsers.length == 0}
        onClick={() => {
          setOpenTransferDialog(!openTransferDialog);
        }}
      >
        <TransferWithinAStation />
        Chuyển vùng dữ liệu, mạng ({selectedUsers.length})
      </Button>
      <Button color="success" simple onClick={() => setOpenCreate(true)}>
        <PersonAdd />
        Thêm
      </Button>
      <Button color="primary" simple onClick={() => setOpenBlogDialog(true)}>
        <Settings />
        Setting Block Users
      </Button>
      <Button color="success" simple onClick={() => setOpenEditDialog(true)}>
        <Edit />
        Update User
      </Button>
      <FormControlLabel
        control={
          <Checkbox
            checked={showDeletedUser}
            onChange={() => setShowDeletedUser(!showDeletedUser)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label="User đã xóa"
      />
    </GridToolbarContainer>
  );

  const columns = [
    // {
    //   field: "fullName",
    //   headerName: "Họ tên",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 180,
    //   valueGetter: (params) => {
    //     console.log("params", params);
    //     return `${params.getValue("firstName") || ""} ${params.getValue("lastName") || ""}`
    //   },
    // },
    {
      field: "userName",
      headerName: "Tên đăng nhập",
      sortable: true,
      width: 180,
    },
    {
      field: "mobileNetworkSupplier",
      headerName: "Nhà mạng",
      width: 180,
      sortable: true,
    },
    {
      field: "dataCategory",
      headerName: "Vùng dữ liệu",
      width: 180,
      renderCell: (param) => displayDataCategory(param),
    },
    {
      field: "isActive",
      headerName: "Trạng thái",
      width: 150,
      renderCell: (param) => (param ? "Đang hoạt động" : "Dừng hoạt động"),
    },
  ];

  const displayDataCategory = (dataCategoryListStr) => {
    if (!dataCategoryListStr || !dataCategoryListStr.value) return null;
    const datacategoryList = dataCategoryListStr.value.split(",");
    let listCategoryName = [];
    for (let index = 0; index < datacategoryList.length; index++) {
      const categoryId = datacategoryList[index];
      const category = listCategory.filter((item) => item.key == categoryId);
      if (category && category.length > 0) {
        listCategoryName.push(category[0].value);
      } else {
        listCategoryName.push(`(${categoryId})`);
      }
    }

    return listCategoryName.join(", ");
  };

  const handleGetUserList = () => {
    setOpenLoading(true);
    getUserList()
      .then((response) => {
        setOpenLoading(false);
        if (response.data) {
          setUserList(response.data);
        }
      })
      .catch((err) => {
        console.error(err)
        setOpenLoading(false);
      });
  };

  const getUserList = async () => {
    const currentSession = getSession();
    if (currentSession.userRoles != "Admin") {
      return await fetchClient.get("/users/iUser");
    } else {
      return fetchClient.get("/users/iUser-my-team");
    }
  };

  const getDataCategory = async () => {
    try {
      const result = await fetchClient.get("/category/categoryActive/");
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserListByStatus = () => {
    if (!userList || userList.length === 0) return [];
    return userList.filter((item) => item.isActive && !showDeletedUser);
  };

  const handleDisableUser = (user) => { };

  const handleCategoryList = () => {
    getDataCategory().then((data) => {
      if (data) {
        var dataResult = data.data || [];
        var listCategory = [];
        dataResult.map((data) => {
          listCategory.push({
            key: data.id,
            value: data.categoryName,
          });
        });
        setCategoryList(listCategory);
      }
    });
  };

  const handleCloseDialog = () => {
    setOpenCreate(false);
    setUserCreate({
      UserName: "",
      Password: "",
      FirstName: "",
      LastName: "",
      Role: role,
      IsActive: "",
      Email: "",
      MobileNetworkSupplier: [],
      DataCategory: [],
    });
    setErrorText({});
    setErrorFile({});
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (validate()) {
      setOpenLoading(true);
      formData.append("FirstName", userCreate.FirstName);
      formData.append("LastName", userCreate.LastName);
      formData.append("Email", userCreate.Email);
      formData.append("UserName", userCreate.UserName);
      formData.append("IsActive", Boolean(userCreate.IsActive));
      formData.append("Role", userCreate.Role);
      formData.append("Password", userCreate.Password);
      formData.append("ConfirmPassword", userCreate.Password);
      formData.append("DataCategory", userCreate.DataCategory);
      formData.append(
        "MobileNetworkSupplier",
        userCreate.MobileNetworkSupplier
      );
      console.log("userCreate", userCreate);
      try {
        const result = await fetchClient.post(
          "/identity/register-user",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result) {
          setOpenLoading(false);
          handleCloseDialog();
          getUserList();
          return result;
        }
      } catch (error) {
        setOpenLoading(false);
        console.log(error);
      }
    }
  };
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;
    const {
      FirstName,
      LastName,
      Email,
      UserName,
      IsActive,
      Role,
      Password,
      DataCategory,
      MobileNetworkSupplier,
    } = userCreate;

    if (!FirstName) {
      numberErr++;
      error.FirstName = true;
      helperText.FirstName = "Đây là trường bắt buộc";
    }

    if (!LastName) {
      numberErr++;
      error.LastName = true;
      helperText.LastName = "Đây là trường bắt buộc";
    }

    if (!Email) {
      numberErr++;
      error.Email = true;
      helperText.Email = "Đây là trường bắt buộc";
    }
    if (Email && !validateEmail(Email)) {
      numberErr++;
      error.Email = true;
      helperText.Email = "Không đúng định dạng email";
    }

    if (!UserName) {
      numberErr++;
      error.UserName = true;
      helperText.UserName = "Đây là trường bắt buộc";
    }
    if (UserName && UserName.length < 6) {
      numberErr++;
      error.UserName = true;
      helperText.UserName = "Độ dài tối thiểu là 6";
    }

    if (IsActive.toString() === "") {
      numberErr++;
      error.IsActive = true;
      helperText.IsActive = "Đây là trường bắt buộc";
    }

    if (Role.toString() === "") {
      numberErr++;
      error.Role = true;
      helperText.Role = "Đây là trường bắt buộc";
    }

    if (!Password) {
      numberErr++;
      error.Password = true;
      helperText.Password = "Đây là trường bắt buộc";
    }

    if (Password && Password.length < 6) {
      numberErr++;
      error.Password = true;
      helperText.Password = "Độ dài tối thiểu là 6";
    }
    if (Password && Password.length >= 6) {
      var regExpAZ = /[A-Z]/g;
      var regExpaz = /[a-z]/g;
      if (!regExpAZ.test(Password) || !regExpaz.test(Password)) {
        numberErr++;
        error.Password = true;
        helperText.Password = "Mật khẩu phải bao gồm cả chữ hoa và chữ thường";
      }
    }

    if (!DataCategory.length) {
      numberErr++;
      error.DataCategory = true;
      helperText.DataCategory = "Đây là trường bắt buộc";
    }
    if (!MobileNetworkSupplier.length) {
      numberErr++;
      error.MobileNetworkSupplier = true;
      helperText.MobileNetworkSupplier = "Đây là trường bắt buộc";
    }
    setErrorText(helperText);
    setErrorFile(error);

    return !numberErr;
  };

  const onchangeInput = (fieldName, value) => {
    setUserCreate({ ...userCreate, [fieldName]: value });
  };

  const onRowSelected = (param) => {
    const { data = {} } = param;
    if (param.isSelected) {
      const newSelectedUser = [...selectedUsers];
      newSelectedUser.push(data);
      setSelectedUsers(newSelectedUser);
    } else {
      _.remove(selectedUsers, (item) => item.id == data.id);
      const newSelectUser = [...selectedUsers];
      setSelectedUsers(newSelectUser);
    }
  };

  const transferSuccess = () => {
    setSelectedUsers([]);
    getUserList()
      .then((response) => {
        if (response.data) {
          setUserList(response.data);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={openLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <SupervisorAccountIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Danh sách nhân viên</h4>
            </CardHeader>
            <CardBody>
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  // autoHeight
                  // autoPageSize
                  rows={getUserListByStatus()}
                  disableColumnSelector={showDeletedUser}
                  columns={columns}
                  checkboxSelection
                  components={{ Toolbar: customToolbar }}
                  onRowSelected={onRowSelected}
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <Dialog
          open={openCreate}
          fullWidth
          maxWidth="md"
          onClose={() => setOpenCreate(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {" Đăng ký tài khoản"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Tài khoản"
                    inputProps={{
                      defaultValue: userCreate.UserName,
                      onChange: (e) =>
                        onchangeInput("UserName", e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    helperText={errorText.UserName ? errorText.UserName : ""}
                    error={errorFile.UserName}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="Role" className={classes.selectLabel}>
                      Loại tài khoản
                    </InputLabel>
                    <Select
                      value={userCreate.Role}
                      onChange={(e) => onchangeInput("Role", e.target.value)}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      error={errorFile.Role}
                      disabled
                    >
                      {/* <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Loại tài khoản
                      </MenuItem> */}
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value={"User"}
                      >
                        User
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value={"Admin"}
                      >
                        Admin
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {errorText.Role ? errorText.Role : ""}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10} md={10}>
                  <CustomInput
                    labelText="Email"
                    inputProps={{
                      defaultValue: userCreate.UserName,
                      onChange: (e) => onchangeInput("Email", e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    helperText={errorText.Email ? errorText.Email : ""}
                    error={errorFile.Email}
                  />
                </GridItem>
                <GridItem xs={12} sm={10} md={10}>
                  <CustomInput
                    labelText="Mật khẩu"
                    inputProps={{
                      defaultValue: userCreate.Password,
                      onChange: (e) =>
                        onchangeInput("Password", e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    helperText={errorText.Password ? errorText.Password : ""}
                    error={errorFile.Password}
                  />
                </GridItem>
                <GridItem xs={12} sm={5} md={5}>
                  <CustomInput
                    labelText="Họ"
                    inputProps={{
                      defaultValue: userCreate.fullName,
                      onChange: (e) =>
                        onchangeInput("FirstName", e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    helperText={errorText.FirstName ? errorText.FirstName : ""}
                    error={errorFile.FirstName}
                  />
                </GridItem>
                <GridItem xs={12} sm={5} md={5}>
                  <CustomInput
                    labelText="Tên"
                    inputProps={{
                      defaultValue: userCreate.fullName,
                      onChange: (e) =>
                        onchangeInput("LastName", e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    helperText={errorText.LastName ? errorText.LastName : ""}
                    error={errorFile.LastName}
                  />
                </GridItem>
                <GridItem xs={12} sm={10} md={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="status"
                      className={classes.selectLabel}
                    >
                      Trạng Thái
                    </InputLabel>
                    <Select
                      defaultValue={1}
                      value={userCreate.IsActive}
                      onChange={(e) =>
                        onchangeInput("IsActive", e.target.value)
                      }
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      error={errorFile.IsActive}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Trạng Thái
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value={0}
                      >
                        Không hoạt động
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value={1}
                      >
                        Hoạt động
                      </MenuItem>
                    </Select>
                    <FormHelperText error>
                      {errorText.IsActive ? errorText.IsActive : ""}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10} md={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="MobileNetworkSupplier"
                      className={classes.selectLabel}
                    >
                      Giới hạn mạng điện thoại
                    </InputLabel>
                    <Select
                      multiple
                      value={userCreate.MobileNetworkSupplier}
                      onChange={(e) =>
                        onchangeInput("MobileNetworkSupplier", e.target.value)
                      }
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      error={errorFile.MobileNetworkSupplier}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Giới hạn mạng điện thoại
                      </MenuItem>
                      {MobileNetworkSupplierList &&
                        MobileNetworkSupplierList.map((e, i) => {
                          return (
                            <MenuItem
                              key={i + i}
                              classes={{
                                root: classes.selectMenuItem,
                                selected:
                                  classes.selectMenuItemSelectedMultiple,
                              }}
                              value={e.value}
                            >
                              {e.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText error>
                      {errorText.MobileNetworkSupplier ? errorText.MobileNetworkSupplier : ""}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10} md={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="MobileNetworkSupplier"
                      className={classes.selectLabel}
                    >
                      Giới hạn dữ liệu
                    </InputLabel>
                    <Select
                      multiple
                      value={userCreate.DataCategory}
                      onChange={(e) =>
                        onchangeInput("DataCategory", e.target.value)
                      }
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      error={errorFile.DataCategory}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Giới hạn dữ liệu
                      </MenuItem>
                      {listCategory &&
                        listCategory.map((e, i) => {
                          return (
                            <MenuItem
                              key={i + i}
                              classes={{
                                root: classes.selectMenuItem,
                                selected:
                                  classes.selectMenuItemSelectedMultiple,
                              }}
                              value={e.key}
                            >
                              {e.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText error>
                      {errorText.DataCategory ? errorText.DataCategory : ""}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="danger">
              Hủy bỏ
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Đăng ký
            </Button>
          </DialogActions>
        </Dialog>
        <Transfer
          open={openTransferDialog}
          selectedUsers={selectedUsers}
          handleClose={() => setOpenTransferDialog(false)}
          onSave={() => {
            transferSuccess();
            setOpenTransferDialog(false);
          }}
        />
        <DialogBlockUser
          open={openBlogDialog}
          classes={classes}
          handleClose={() => setOpenBlogDialog(false)}
        ></DialogBlockUser>
        <DialogEdit
          open={openEditDialog}
          classes={classes}
          handleClose={() => setOpenEditDialog(false)}
        ></DialogEdit>
      </GridContainer>
    </div>
  );
}

const DialogBlockUser = (props) => {

  const [usersTextSearch, setUsersTextSearch] = useState("");
  const [data, setData] = useState([]);
  const { open, classes, handleClose } = props
  const [openLoading, setOpenLoading] = useState(false);

  const getUserListByName = async () => {
    try {
      let result;
      const currentSession = getSession();
      if (currentSession.userRoles != "Admin") {
        result = await fetchClient.get(`/users/iUserByUserName/?userName=${usersTextSearch}`);
      }
      else {
        result = await fetchClient.get(`/users/iUser-my-team/?userName=${usersTextSearch}`);
      }
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handSearch();
  }, [open])

  const closeDialog = () => {
    setUsersTextSearch("");
    handleClose();
  }

  const RoundButtons = (props) => {
    const { block, onclick } = props
    return (
      <>
        {block ? <Button
          round
          color='success'
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={onclick}
        >
          <Visibility className={classes.icon} />
        </Button> :
          <Button
            round
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={onclick}
          >
            <VisibilityOff className={classes.icon} />
          </Button>}
      </>
    );
  };

  const blockUser = async (id, status) => {
    var query = queryString.stringify({ userId: id, status: status }, { arrayFormat: 'comma' });
    return await fetchClient.post(`/users/block-user/?${query}`);
  };

  const handleBlogUsers = (id, status) => {
    blockUser(id, status).then((result) => {
      if (result) {
        handSearch();
      }
    }

    );
  };

  const handSearch = () => {
    setOpenLoading(true)
    getUserListByName().then((response) => {
      try {
        if (response) {
          var dataTable = response.data;
          var dataMapTable = [];
          dataTable.forEach((e) => {
            dataMapTable.push([
              e.userName,
              e.email,
              e.isActive ?
                <p style={{ color: 'blue' }}>Đang hoạt động</p> : <p style={{ color: 'red' }}>Dừng hoạt động</p>,
              <RoundButtons
                onclick={() => handleBlogUsers(e.id, !e.isActive)}
                block={e.isActive}
              ></RoundButtons>
            ]);
          });
          setData(dataMapTable);
          setOpenLoading(false)
        }
      } catch (error) {
        setOpenLoading(false)
        console.log(error)
      }
    });
  };
  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Chuyển trạng thái của nhân viên
      </DialogTitle>
      <DialogContent>
        <Backdrop className={classes.backdrop} open={openLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SupervisorAccountIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Danh sách nhân viên</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={8} sm={8} md={8}>
                <CustomInput
                  labelText="Tài khoản"
                  inputProps={{
                    defaultValue: usersTextSearch,
                    onChange: (e) =>
                      setUsersTextSearch(e.target.value),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <Button color="primary" onClick={handSearch}><Search />Tìm kiếm</Button>
              </GridItem>
            </GridContainer>
            <Table
              tableHead={[
                "Tài khoản",
                "Email",
                "Trạng thái",
                "Hành động",
              ]}
              tableData={[...data]}
            />
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Đóng
        </Button>
      </DialogActions>
    </Dialog>)
}

const DialogEdit = (props) => {

  const [usersTextSearch, setUsersTextSearch] = useState("");
  const [data, setData] = useState([]);
  const { open, classes, handleClose } = props
  const [openLoading, setOpenLoading] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogUpdatePass, setOpenDialogUpdatePass] = useState(false);
  const [dataDialogEdit, setDataDialogEdit] = useState({});
  const [listUser, setListUser] = useState([]);

  const getUserListByName = async () => {
    try {
      let result;
      const currentSession = getSession();
      if (currentSession.userRoles != "Admin") {
        result = await fetchClient.get(`/users/iUserByUserName/?userName=${usersTextSearch}`);
      }
      else {
        result = await fetchClient.get(`/users/iUser-my-team/?userName=${usersTextSearch}`);
      }
      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handSearch();
  }, [open])

  const closeDialog = () => {
    setUsersTextSearch("");
    handleClose();
  }

  const handleSubmitEdit = async (id, userName) => {
    var query = queryString.stringify({ userId: id, userName: userName }, { arrayFormat: 'comma' });
    return await fetchClient.post(`/identity/edit-username/?${query}`);
  };

  const handleSubmitEditPass = async (id, password) => {
    var query = queryString.stringify({ userId: id, password: password }, { arrayFormat: 'comma' });
    return await fetchClient.post(`/identity/reset-password-admin/?${query}`);
  };

  const handleUpdateDialog = (e) => {
    setOpenDialogEdit(true);
    setDataDialogEdit(e)

  }

  const handleUpdatePassDialog = (e) => {
    setOpenDialogUpdatePass(true);
    setDataDialogEdit(e)

  }
  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    setDataDialogEdit({})
  }

  const handleCloseUpdatePass = () => {
    setOpenDialogUpdatePass(false);
    setDataDialogEdit({})
  }

  const handSearch = () => {
    setOpenLoading(true)
    getUserListByName().then((response) => {
      try {
        if (response) {
          var dataTable = response.data;
          setListUser(response.data)
          var dataMapTable = [];
          dataTable.forEach((e) => {
            dataMapTable.push([
              e.userName,
              e.email,
              <>
                <Button
                  round
                  className={classes.actionButton + " " + classes.actionButtonRound}
                  onClick={() => handleUpdateDialog(e)}
                  color="success"
                >
                  <Edit color="success" />
                </Button>
                <Button
                  round
                  className={classes.actionButton + " " + classes.actionButtonRound}
                  onClick={() => handleUpdatePassDialog(e)}
                  color="danger"
                >
                  <VpnKey color="danger" />
                </Button>
              </>
            ]);
          });
          setData(dataMapTable);
          setOpenLoading(false)
        }
      } catch (error) {
        setOpenLoading(false)
        console.log(error)
      }
    });
  };
  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Thay đổi thông tin đăng nhập
      </DialogTitle>
      <DialogContent>
        <Backdrop className={classes.backdrop} open={openLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SupervisorAccountIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Danh sách nhân viên</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={8} sm={8} md={8}>
                <CustomInput
                  labelText="Tài khoản"
                  inputProps={{
                    defaultValue: usersTextSearch,
                    onChange: (e) =>
                      setUsersTextSearch(e.target.value),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <Button color="primary" onClick={handSearch}><Search />Tìm kiếm</Button>
              </GridItem>
            </GridContainer>
            <Table
              tableHead={[
                "Tài khoản",
                "Email",
                "Hành động",
              ]}
              tableData={[...data]}
            />
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Đóng
        </Button>
      </DialogActions>
      <UpdateUser
        openDialog={openDialogEdit}
        handleCloseDialog={handleCloseDialogEdit}
        dataDialog={dataDialogEdit}
        handleSubmit={handleSubmitEdit}
        listUser={listUser}
        handSearch={handSearch}
      ></UpdateUser>
      <UpdatePassword
        openDialog={openDialogUpdatePass}
        handleCloseDialog={handleCloseUpdatePass}
        dataDialog={dataDialogEdit}
        handleSubmit={handleSubmitEditPass}
        listUser={listUser}
        handSearch={handSearch}
      ></UpdatePassword>
    </Dialog>)
}

const UpdateUser = (props) => {
  const { openDialog, handleCloseDialog, dataDialog, handleSubmit, listUser, handSearch } = props
  const [errorText, setErrorText] = useState({});
  const [errorField, setErrorField] = useState({});
  const [userName, setUserName] = useState();


  useEffect(() => {
    if (dataDialog) {
      setUserName(dataDialog.userName)
    }
  }, [openDialog])

  const closeDialog = () => {
    setErrorText({});
    setErrorField({});
    setUserName();
    handleCloseDialog();
  }

  const submitEdit = () => {
    if (validate()) {
      handleSubmit(dataDialog.id, userName).then((result) => {
        if (result && result.data) {
          handSearch();
          closeDialog();
        }
      })
    }
  }

  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;


    if (!userName) {
      numberErr++;
      error.userName = true;
      helperText.userName = "Đây là trường bắt buộc";
    }

    if (userName && userName.length < 6) {
      numberErr++;
      error.userName = true;
      helperText.userName = "Độ dài tối thiểu là 6";
    }

    var checkHasUserName = listUser.filter(x => x.id != dataDialog.id && x.userName == userName).length;
    if (userName && userName.length > 6 && checkHasUserName > 0) {
      numberErr++;
      error.userName = true;
      helperText.userName = "Tên này đã tồn tại";
    }
    if (userName && userName.length > 6 && userName.split(" ").length > 1) {
      numberErr++;
      error.userName = true;
      helperText.userName = "Tên không được chưa kí tự khoảng trắng";
    }

    setErrorText(helperText);
    setErrorField(error);
    return !numberErr;
  };


  return (<Dialog
    open={openDialog}
    fullWidth
    maxWidth="sm"
    onClose={handleCloseDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      Sửa tên đăng nhập cho <strong style={{ color: 'blue' }}>{dataDialog.userName}</strong>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Tên đăng nhập mới"
              inputProps={{
                value: userName,
                onChange: (e) =>
                  setUserName(e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.userName ? errorText.userName : ""}
              error={errorField.userName}
            />
          </GridItem>
        </GridContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={closeDialog} color="danger">
        Hủy bỏ
      </Button>
      <Button onClick={submitEdit} color="primary" autoFocus>
        Lưu
      </Button>
    </DialogActions>
  </Dialog>)
}

const UpdatePassword = (props) => {
  const { openDialog, handleCloseDialog, dataDialog, handleSubmit, listUser, handSearch } = props
  const [errorText, setErrorText] = useState({});
  const [errorField, setErrorField] = useState({});
  const [password, setPassword] = useState();


  const closeDialog = () => {
    setErrorText({});
    setErrorField({});
    setPassword();
    handleCloseDialog();
  }

  const submitEdit = () => {
    if (validate()) {
      handleSubmit(dataDialog.id, password).then((result) => {
        if (result && result.data) {
          handSearch();
          closeDialog();
        }
      })
    }
  }

  const validate = () => {
    var error = {};
    var helperText = {};
    var numberErr = 0;


    if (!password) {
      numberErr++;
      error.password = true;
      helperText.password = "Đây là trường bắt buộc";
    }

    if (password && password.length < 6) {
      numberErr++;
      error.password = true;
      helperText.password = "Độ dài tối thiểu là 6";
    }
    if (password && password.length >= 6) {
      var regExpAZ = /[A-Z]/g;
      var regExpaz = /[a-z]/g;
      if (!regExpAZ.test(password) || !regExpaz.test(password)) {
        numberErr++;
        error.password = true;
        helperText.password = "Mật khẩu phải bao gồm cả chữ hoa và chữ thường";
      }
    }

    setErrorText(helperText);
    setErrorField(error);
    return !numberErr;
  };


  return (<Dialog
    open={openDialog}
    fullWidth
    maxWidth="sm"
    onClose={handleCloseDialog}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      Sửa mật khẩu cho <strong style={{ color: 'blue' }}>{dataDialog.userName}</strong>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <CustomInput
              labelText="Mật khẩu mới"
              inputProps={{
                value: password,
                onChange: (e) =>
                  setPassword(e.target.value),
              }}
              formControlProps={{
                fullWidth: true,
              }}
              helperText={errorText.password ? errorText.password : ""}
              error={errorField.password}
            />
          </GridItem>
        </GridContainer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={closeDialog} color="danger">
        Hủy bỏ
      </Button>
      <Button onClick={submitEdit} color="primary" autoFocus>
        Lưu
      </Button>
    </DialogActions>
  </Dialog>)
}