import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { DropzoneArea } from "material-ui-dropzone";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { Assignment, CloudDownload, PhoneEnabledOutlined } from "@material-ui/icons";
import Table from "components/Table/Table.js";
import Check from "@material-ui/icons/Check";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import fetchClient from "../../ultils/request";
import { getSession } from "../../ultils/appSession";
import { transform } from "lodash";
import * as XLSX from "xlsx";

const stylesForm = {
    backdrop: {
        zIndex: 10000,
        color: "#fff",
    },
};
const useStyles = makeStyles(stylesForm);
const tem = { "inputDuplicationPhoneNumber": [], "existingPhoneNumber": [], "nonExistingPhoneNumber": ["0382974147", "0979432713"], "inputDuplicationPhoneNumberCount": 0, "existingPhoneNumberCount": 0, "nonExistingPhoneNumberSetCount": 2 }
const phoneCheckType = {
    existingPhoneNumber: "existingPhoneNumber",
    nonExistingPhoneNumber: "nonExistingPhoneNumber",
    inputDuplicationPhoneNumber: "inputDuplicationPhoneNumber",
}


export default function CheckCustomerInfoPage() {
    const [file, setFiles] = useState();
    const [fileName, setFileName] = useState();
    const [phoneNumberData, setPhoneNumberData] = useState();
    const [tableData, setTableData] = useState([]);
    const [openLoading, setOpenLoading] = useState(false);
    const isDiableCheckButton = file == null || file == undefined;
    const checkData = async () => {
        if (file) {
            console.log(file);
            try {
                const formData = new FormData();
                formData.append("file", file);
                const result = await fetchClient.post("/customerInfo", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                return result;
            } catch (e) {
                console.log(e);
            }
        }
    }

    const transformToTableData = (data) => {
        const tableData = [
            ["SĐT mới", data.nonExistingPhoneNumberSetCount, <Button
                round
                key={phoneCheckType.nonExistingPhoneNumber}
                onClick={() => exportCsvFile(data[phoneCheckType.nonExistingPhoneNumber], phoneCheckType.nonExistingPhoneNumber, file.name)}
            >
                <CloudDownload />
            </Button>],
            ["SĐT trùng trong file", data.inputDuplicationPhoneNumberCount, <Button
                round
                key={phoneCheckType.inputDuplicationPhoneNumber}
                onClick={() => exportCsvFile(data[phoneCheckType.inputDuplicationPhoneNumber], phoneCheckType.inputDuplicationPhoneNumber, file.name)}
            >
                <CloudDownload />
            </Button>],
            ["SĐT đã tồn tại trên hệ thống", data.existingPhoneNumberCount, <Button
                round
                key={phoneCheckType.existingPhoneNumber}
                onClick={() => exportCsvFile(data[phoneCheckType.existingPhoneNumber], phoneCheckType.existingPhoneNumber, file.name)}
            >
                <CloudDownload />
            </Button>],

        ];

        setTableData(tableData);
    }

    const handlerSubmitButton = () => {
        setOpenLoading(true);
        checkData().then((response) => {
            console.log('response.data', response.data);
            setPhoneNumberData(response.data);
            transformToTableData(response.data);
            setOpenLoading(false);
        });
    }

    const exportCsvFile = (records, type, originalFileName) => {
        const newFileName = removeFileExt(originalFileName) + type + ".xlsx";
        const excelRecords = records.map(item => { return { SDT: item } });
        const ws = XLSX.utils.json_to_sheet(excelRecords);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, type);
        XLSX.writeFile(wb, newFileName);
    }

    const removeFileExt = (fileName) => {
        return fileName.replace(/\.[^/.]+$/, "")
    }

    const classes = useStyles();
    return (
        <>
            <Backdrop className={classes.backdrop} open={openLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={6}>
                            <DropzoneArea
                                onChange={(files) => setFiles(files[0])}
                                showPreviews={false}
                                maxFileSize={209715200}
                                filesLimit={1}
                                dropzoneText="Kéo, thả file hoặc click để chọn file. Định dạng CSV với một cột SĐT."
                                clearOnUnmount
                                fullWidth
                                acceptedFiles={[
                                    ".csv",
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6}>
                            <Button variant="contained"
                                color="info"
                                onClick={handlerSubmitButton}
                                disabled={isDiableCheckButton}
                            >
                                Kiểm tra data
                            </Button>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Loại", "Số lượng", "Tải xuống"]}
                                coloredColls={[3]}
                                colorsColls={["primary"]}
                                tableData={tableData}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </>
    )
}