import React, { useState, useEffect } from "react";
import moment from "moment";
import queryString from 'query-string';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Call from "@material-ui/icons/Call";
import PersonAdd from "@material-ui/icons/PersonAdd";
import PhoneDisabled from "@material-ui/icons/PhoneDisabled";
import PersonAddDisabled from "@material-ui/icons/PersonAddDisabled";
import Warning from "@material-ui/icons/Warning";
import Search from "@material-ui/icons/Search";
import GetApp from "@material-ui/icons/GetApp";
import SkipNext from "@material-ui/icons/SkipNext";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Assignment from "@material-ui/icons/Assignment";

import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import fetchClient from "../../ultils/request";

const stylesForm = {
  ...styles,
  errorText: {
    color: "red",
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
  displayFlex: {
    display: 'flex'
  },
};
var today = {
  TimeStart: moment(Date.now()).format("YYYY/MM/DD 00:00:00"),
  TimeEnd: moment(Date.now()).format("YYYY/MM/DD 23:59:59"),
}

const useStyles = makeStyles(stylesForm);
const statusCall = {
  Waitting: 0,
  Accepted: 1,
  Rejected: 2,
  MissCall: 3,
  WrongPhoneNumber: 4,
  Ignore: 5,
  Locked: 6,
};
export default function Home() {
  const classes = useStyles();
  const [openLoading, setOpenLoading] = useState(false);


  const [dataExport, setDataExport] = useState(today);

  const [dataCall, setDataCall] = useState({
    Accepted: 0,
    Rejected: 0,
    MissCall: 0,
    WrongPhoneNumber: 0,
    WrongPhoneNumber: 0,
    Ignore: 0,
  });
  const [dataCallFollowUser, setDataCallFollowUser] = useState([]);

  useEffect(() => {
    getListData(today.TimeStart, today.TimeEnd);
    getListDataFollowUser();
  }, []);

  const getListData = async (start, end) => {
    try {
      var query = queryString.stringify({
        TimeStart: start,
        TimeEnd: end
      }, { arrayFormat: 'comma' });
      const result = await fetchClient(`/v1/UserCallLogs/getAllByTime/?${query}`);
      if (result.data) {
        var data = result.data;
        var tran = {};
        data.forEach((e) => {
          tran[e.logStatus] = e.count;
        });
        setDataCall(tran);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getListDataFollowUser = async () => {
    try {
      setOpenLoading(true);
      var query = queryString.stringify(dataExport, { arrayFormat: 'comma' });
      const result = await fetchClient(`/v1/UserCallLogs/getCallFollowUsers/?${query}`);
      setOpenLoading(false);
      if (result.data) {
        var data = result.data;
        var dataTable = [];
        data.forEach((e) => {
          dataTable.push([
            e.userName,
            e.accept,
            e.reject,
            e.missCall,
            e.wrongPhoneNumber,
            e.ignore,
            e.all,
          ]);
        });
        setDataCallFollowUser(dataTable);
      }
    } catch (error) {
      console.log(error);
      setOpenLoading(false);
    }
  };

  const convertToArrBuffer = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const ExportListDataFollowUser = async () => {
    try {
      setOpenLoading(true);
      var query = queryString.stringify(dataExport, { arrayFormat: 'comma' });
      const result = await fetchClient(`/v1/UserCallLogs/getCallFollowUsers/?${query}&Export=True`);
      if (result.data) {
        var blob = new Blob([convertToArrBuffer(atob(result.data))], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const fileName =
          "TeleSale_User_Export_" +
          moment(Date.now()).format("MM-DD-YYYY_HH-mm-ss");
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        setOpenLoading(false);
      }
    } catch (error) {
      console.log(error);
      setOpenLoading(false);
    }
  };

  const onchangeInputExport = (fieldName, value) => {
    if (value) {
      value = moment(value).format("YYYY/MM/DD HH:mm:ss")
    }
    setDataExport({ ...dataExport, [fieldName]: value });
  };
  return (
    <>
      <GridContainer>
        <Backdrop className={classes.backdrop} open={openLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DisplayDataCall title="Thống kê theo trạng thái trả lời"
          dataCall={dataCall}
          classes={classes}
          getListData={getListData}
        ></DisplayDataCall>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Thống kê theo nhân viên</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <InputLabel className={classes.label}>
                  Thời gian bắt đầu
                  </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    closeOnSelect
                    dateFormat={"YYYY/MM/DD"}
                    timeFormat={"HH:mm:ss"}
                    defaultValue={dataExport.TimeStart}
                    inputProps={{ placeholder: "Thời gian bắt đầu" }}
                    onChange={(e) => onchangeInputExport("TimeStart", e._d)}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InputLabel className={classes.label}>
                  Thời gian kết thúc
                  </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    closeOnSelect
                    dateFormat={"YYYY/MM/DD"}
                    timeFormat={"HH:mm:ss"}
                    defaultValue={dataExport.TimeEnd}
                    inputProps={{ placeholder: "Thời gian kết thúc" }}
                    onChange={(e) => onchangeInputExport("TimeEnd", e._d)}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Button color="primary" onClick={getListDataFollowUser}><Search />Tìm kiếm</Button>
                <Button color="success" onClick={ExportListDataFollowUser}><GetApp /> Export </Button>
              </GridItem>
            </GridContainer>

            <Table
              tableHead={[
                "Tên",
                "Số lượng đồng ý",
                "Số lượng từ chối",
                "Số lượng không nhấc máy",
                "Số lượng bị lỗi",
                "Số lượng bỏ qua",
                "Tổng",
              ]}
              tableData={[...dataCallFollowUser]}
              customCellClasses={[
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.left,
              ]}
              customClassesForCells={[1, 2, 3, 4, 5, 6, 7, 8]}
              customHeadCellClasses={[
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.left,
              ]}
              customHeadClassesForCells={[1, 2, 3, 4, 5, 6, 7, 8]}
            />
          </CardBody>
        </Card>
      </GridContainer>
    </>
  );
}
const DisplayDataCall = (props) => {
  const { title, dataCall, classes, getListData } = props

  const checkNumber = (number) => {

    return Number(number) || 0

  }
  const dataAll = checkNumber(dataCall.Accepted) + checkNumber(dataCall.MissCall)
    + checkNumber(dataCall.Rejected) + checkNumber(dataCall.WrongPhoneNumber) + checkNumber(dataCall.Ignore)

  const [dataExport, setDataExport] = useState({
    TimeStart: moment(Date.now()).format("YYYY/MM/DD 00:00:00"),
    TimeEnd: moment(Date.now()).format("YYYY/MM/DD 23:59:59"),
  });

  const onchangeInputExport = (fieldName, value) => {
    if (value) {
      value = moment(value).format("YYYY/MM/DD HH:mm:ss")
    }
    setDataExport({ ...dataExport, [fieldName]: value });
  };

  return (
    <Card>
      <CardHeader color="success" icon>
        <CardIcon color="success">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{title}</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InputLabel className={classes.label}>
              Thời gian bắt đầu
                  </InputLabel>
            <FormControl fullWidth>
              <Datetime
                closeOnSelect
                dateFormat={"YYYY/MM/DD"}
                timeFormat={"HH:mm:ss"}
                defaultValue={dataExport.TimeStart}
                inputProps={{ placeholder: "Thời gian bắt đầu" }}
                onChange={(e) => onchangeInputExport("TimeStart", e._d)}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InputLabel className={classes.label}>
              Thời gian kết thúc
                  </InputLabel>
            <FormControl fullWidth>
              <Datetime
                closeOnSelect
                dateFormat={"YYYY/MM/DD"}
                timeFormat={"HH:mm:ss"}
                defaultValue={dataExport.TimeEnd}
                inputProps={{ placeholder: "Thời gian kết thúc" }}
                onChange={(e) => onchangeInputExport("TimeEnd", e._d)}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Button color="primary" onClick={() => getListData(dataExport.TimeStart, dataExport.TimeEnd)}><Search />Tìm kiếm</Button>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Call />
                </CardIcon>
                <p className={classes.cardCategory}>Tổng số cuộc gọi</p>
                <h3 className={classes.cardTitle}>
                  {checkNumber(dataAll)}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <PersonAdd />
                </CardIcon>
                <p className={classes.cardCategory}>Số lượng khách đồng ý</p>
                <h3 className={classes.cardTitle}>{checkNumber(dataCall.Accepted)}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <PhoneDisabled />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Số lượng khách không bắt máy
      </p>
                <h3 className={classes.cardTitle}>{checkNumber(dataCall.MissCall)}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <PersonAddDisabled />
                </CardIcon>
                <p className={classes.cardCategory}>Số lượng khách từ chối</p>
                <h3 className={classes.cardTitle}>{checkNumber(dataCall.Rejected)}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="rose">
                  <Warning />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Số lượng không liên lạc được
      </p>
                <h3 className={classes.cardTitle}>{checkNumber(dataCall.WrongPhoneNumber)}</h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <SkipNext />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Số lượng bỏ qua
      </p>
                <h3 className={classes.cardTitle}>{checkNumber(dataCall.Ignore)}</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card >)
}