import React, { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import { PermContactCalendar } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import fetchClient from "../../ultils/request";
import { setSession } from "../../ultils/appSession";
import { FormControl, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import logo from "../../assets/img/logo.svg";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  let history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [messageError, setMessageError] = useState("");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const getToken = async ({ userName, password }) => {
    try {
      const result = await fetchClient.post("/identity/token", {
        userName,
        password,
      });

      const { data } = result.data;
      setSession(data);

      history.push("/dashboard");
    } catch (e) {
      console.error("error", e);
      setMessageError(e.message);
    }
  };

  const classes = useStyles();

  console.log("process.env", process.env);
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Formik
            initialValues={{ userName: "", password: "" }}
            validate={(values) => {
              console.log("values", values);
              const errors = {};
              if (!values.userName) {
                errors.userName = "Bạn chưa nhập tên đăng nhập.";
              }

              if (!values.password) {
                errors.password = "Bạn chưa nhập mật khẩu.";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              // post data to server
              await getToken(values);

              setSubmitting(false);
            }}
          >
            {({
              values: { userName, password },
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => {
              const onChange = (e) => {
                e.persist();
                setFieldValue(e.target.name, e.target.value);
                setFieldTouched(e.target.name, true, false);
              };

              return (
                <Card login className={classes[cardAnimaton]}>
                  <CardHeader className={classes.cardHeader}>
                    <img src={logo} alt="Page logo" />
                    <br />
                    <h3>Đăng nhập</h3>
                  </CardHeader>
                  <CardBody className={classes.lgCardBody}>
                    <FormControl fullWidth>
                      <TextField
                        label="Tên đăng nhập"
                        name="userName"
                        value={userName}
                        helperText={touched.userName ? errors.userName : ""}
                        error={touched.userName && Boolean(errors.userName)}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PermContactCalendar
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "1rem" }}>
                      <TextField
                        id="password"
                        name="password"
                        type="password"
                        label="Mật khẩu"
                        autoComplete="off"
                        value={password}
                        helperText={touched.password ? errors.password : ""}
                        error={touched.password && Boolean(errors.password)}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      onClick={handleSubmit}
                      block
                    >
                      Đăng nhập
                    </Button>
                  </CardFooter>
                </Card>
              );
            }}
          </Formik>
        </GridItem>
      </GridContainer>
      {messageError && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            setMessageError("");
          }}
        >
          <MuiAlert severity="error">{messageError}</MuiAlert>
        </Snackbar>
      )}
    </div>
  );
}
